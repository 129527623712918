export default {
  // ---------route------------------------
  '/user/details/index': '会员详情',
  '/admin/user/index': '后台账号管理',
  '/admin/role/index': '角色权限设定',
  '/admin/securityCenter/index': '认证机制设定',
  '/user/userList/index': '会员管理',
  '/business-manage/memberList/index': '超级会员列表',
  '/business-manage/paymentList/index': '支付渠道设定',
  '/business-manage/bankCard/index': '银行卡设定',
  '/withdraw/config/index': '提现设定',
  '/merchant': '银商管理',
  '/merchant/paymentMethodList/index': '银商支付渠道设定',
  '/merchant/merchantList/index': '银商列表',
  '/merchant/shopList/index': '店铺列表',
  '/merchant/iconList/index': '预设图示列表',
  '/merchant/record/index': '转账交易记录',
  '/merchant/balanceChangeDetail/index': '银商余额账变记录',
  '/gameCenter': '游戏中心',
  '/gameCenter/category/index': '游戏分类',
  '/gameCenter/game/index': '游戏上架',
  '/gameCenter/banner/index': '游戏页广告上架',
  '/merchant/report/index': '银商数据版',
  '/merchant/ask/index': '咨询转账列表',
  '/record': '订单管理',
  '/record/orderPaymentList/index': '支付订单查询',
  '/record/withdraw/index': '提现记录查询',
  '/admin/qaSetting/index': '客服设置',
  '/business-manage/membershipSet/index': '会员套餐',
  '/merchant/bankerRating/index': '银商评分',
  '/record/tradeDetail/index': '资金交易明细',
  '/user/actionLog/index': '操作历程',
  '/record/withdrawAudit/index': '提现审核',
  '/record/userFundsOverview/index': '用户资金总览',
  '/distributor-manage/distributor-withdrawAudit/index': '渠道商提现审核',

  // 遊戲中心
  addCategory: '新增分类',
  categoryName: '分类名称',
  categoryIcon: '分类图示',
  categorySort: '分类排序',
  isTop: '显示于首页列表',
  open: '开启',
  close: '关闭',
  confirm: '确定',
  categoryTip1: '1.仅能开启5个分类显示于App游戏页列表上。',
  categoryTip2:
    '2.每个分类列表內，上架遊戲需达4的倍数該分類才会正常显示於App上，最多显示8个遊戲。如超过8个会在列表上显示更多按钮。',
  searchGameName: '查询游戏名称',
  searchGameCategeory: '查询游戏分类',
  reset: '重置',
  add: '新增',
  serialNumber: '序号',
  gameIcon: '游戏icon',
  gameTitle: '游戏标题',
  gameTitlePlaceholder: '请输入游戏标题',
  gameDesc: '游戏描述',
  gameDescPlaceholder: '请输入游戏描述',
  gameCategeory: '游戏分类',
  gameBetMultiplier: '游戏投注额倍数',
  gameCategeoryPlaceholder: '请选择游戏分类',
  gameTagSetting: '游戏标签设定',
  gameTagSettingPlaceholder: '请输入标签设定',
  gameLink: '游戏连结',
  gameLinkMsg: '请输入游戏连结',
  gameWeight: '游戏权重',
  gameWeightPlaceholder: '请输入权重值1~100',
  gameCategoryTip1: '游戏权重设定会影响到游戏在各别分类表单里的排名。 1为最高排序，后依序下降。',
  showInHotGame: '显示在热门游戏',
  gameCategoryTip2: '热门游戏需开启满8个，App才能正常显示热门排行。8个以下无法显示。',
  operate: '操作',
  delDialogMsg1: '是否确认删除该游戏',
  delDialogConfirmText: '确认删除',
  delDialogCancelText: '我再想想',
  deleteSuccess: '删除成功',
  editSuccess: '编辑成功',
  imgOnlyPng: '上传图片只能是 PNG 格式!',
  gameCategoryModalMsg: '该游戏分类仍有上架游戏存在，是否确认删除整个分类游戏内容',
  categorySortPlaceholder: '请输入分类排序',
  categoryNamePlaceholder: '请输入分类名称',
  categoryIconUrlPlaceholder: '请上传分类图示',
  successCreate: '创建成功',
  advertName: '广告名称',
  startPlaceholder: '开始日期',
  endPlaceholder: '结束日期',
  advertState: '广告状态',
  enable: '启用',
  disable: '停用',
  advertImg: '广告图片',
  advertSort: '广告排序',
  startTime: '开始时间',
  endTime: '结束时间',
  forwardUrl: '跳转地址',
  creater: '创建人',
  delDialogMsg2: '是否确认删除该游戏页广告',
  advertNamePlaceholder: '请输入广告名称',
  advertImgUploadTip: '尺寸：990 * 450 格式档案：png',
  addedTime: '上架时间',
  addedTimePlaceholder: '请输入上架时间',
  advertSortPlaceholder: '请输入广告排序',
  forwardType: '跳转类型',
  outsideLink: '外链',
  insideLink: '内链',
  forwardUrlPlaceholder: '请输入跳转地址',
  advertIconUrlPlaceholder: '请上传广告图片',
  gameIconPlaceholder: '请上传游戏icon图片',

  //提現
  queryUsername: '查询用户昵称',
  queryPhone: '查询手机号',
  moneyRangeMin: '申请金额最小值',
  moneyRangeMax: '申请金额最大值',
  actionStartTime: '申请开始时间',
  actionEndTime: '申请结束时间',
  goodsOrderNum: '商品订单号',
  paymentOrderNum: '支付订单号',
  withdrawBank: '提现银行',
  bankCardNo: '银行卡号',
  query: '查询',
  bankPlaceholder: '选择提款银行',
  withdrawTime: '选择提现时间',
  statusPlaceholder: '选择状态',
  withdrawUserName: '提款姓名',
  applicationTime: '提现时间',
  applicationMoney: '申请金额',
  arrivalTime: '到账时间',
  arrivalMoney: '到账金额',
  extraMoney: '返利',
  goods: '商品',
  tradeMoney: '交易金币数',
  tradeAfterMoney: '交易后余额',
  orderName: '订单名称',
  tradeResult: '交易结果',
  tradeTime: '交易时间',
  bankName: '银行名称',
  cardName: '持卡人姓名',
  bindTime: '绑定时间',
  bankCardDetail: '银行卡详情',
  plus: '加',
  decrease: '扣',
  remark: '备注',
  remarkPlaceHolder: '请输入备注',
  moneyPlaceHolder: '请输入金额',
  withdrawName: '提现标题',
  limitType: '限制类型',
  limitTypePlaceholder: '请输入限制类型',
  limitRange: '限制金额范围',
  limitValue: '限制固定金额',
  minLimit: '限制金额最小值',
  maxLimit: '限制金额最大值',
  limitValuePlaceholder: '请输入限制固定金额',
  withdrawBonus: '提现返利比例',
  withdrawBonusPlaceholder: '请输入提现红利比例',
  activityContent: '活动内容',
  contentPlaceholder: '请输入活动内容',
  specialPoint: '特点内容',
  specialPointPlaceholder: '请输入特点内容',
  withdrawFixedMoney: '提现固定金额',
  withdrawFixedMoneyPlaceholder: '请输入提现固定金额',
  normalContent: '普通提现标题',
  normalContentPlaceholder: '请输入普通提现标题',
  reserveContent: '预约提现标题',
  reserveContentPlaceholder: '请输入预约提现标题',
  resTimeAndBonus: '预约时限和加送',
  reserve: '预约',
  hour: '小时',
  extraBonus: '加送',
  withdrawNamePlaceholder: '请输入提现名称',
  imgSize64: '尺寸：64*64 容量:100kb以下 格式档案：png',
  imgSize72: '尺寸：72*72 容量:100kb以下 格式档案：png',
  imgSize128: '尺寸：128*128 容量:100kb以下 格式档案：png',
  imgSize192: '尺寸：192*192 容量:100kb以下 格式档案：png',
  clickUpload: '点击上传',
  appoSetting: '预约时间',
  timeLimit: '时限',
  timeLimitPlaceholder: '请输入预约时限',
  give: '赠送',
  pleaseEnterRebateRatio: '请输入返利比例',
  giveTip: '最多可增加到9个',
  appWithdrawMoney: 'App提现金额',
  limitFixedPreview: '固定金额菜单(预览)',
  customRange: '自行输入范围',
  fixedMoney: '固定金额',
  rangeMoney: '范围',
  minMaxValueError: '限制金额范围最大值不能小于最小值',
  appoSettingError: '预约设置时限或赠送数值未填写完整',
  imageLimitUploadError: '只能传入一个图片,请删除已上传的图片后再上传',
  imageTypeJpgPngError: '上传图片只能是 JPG/PNG 格式!',
  imageTypePngError: '上传图片只能是 PNG 格式!',
  imageSizeError: '上传图片大小不能超过 100KB!',
  imageSizeError2: '上传图片大小不能超过 5MB!',
  imageWidthHeight64Error: '上传图片尺寸不符合,只能是64x64!',
  imageWidthHeight72Error: '上传图片尺寸不符合,只能是72x72!',
  imageWidthHeight128Error: '上传图片尺寸不符合,只能是128x128!',
  imageWidthHeight192Error: '上传图片尺寸不符合,只能是192x192!',
  imageWidthHeight990x450Error: '上传图片尺寸不符合,只能是990x450!',
  withdrawTitle: '提现方式',
  moneyTip: '最多11个 ex:100,200,300...etc',
  record: '记录',
  transferMoney: '转钱',
  done: '完成',
  userName: '用户名称',
  merchantName: '银商名称',
  askStatus: '谘询状态',
  createTime: '建立时间',
  updateTime: '更新时间',
  selectTimeType: '选择时间类别',
  applyMoneyMin: '到账金额最小值',
  applyMoneyMax: '到账金额最大值',
  selectOrderStatus: '选择商品订单状态',
  selectPayType: '选择支付方式',
  selectPayStatus: '选择支付订单状态',
  bankCode: '银行ID',
  Operator: '操作人',
  OperatorTime: '操作时间',
  bankCodePlaceholder: '请输入银行ID',
  bankNamePlaceholder: '请输入银行名称',
  goodsArrivalTime: '商品到账时间',
  goodsarrivalMoney: '商品到账金额',
  payArrivalTime: '支付到账时间',
  payArrivalMoney: '支付到账金额',
  goodOrderStatus: '商品订单状态',
  payOrderStatus: '支付订单状态',
  fillOrder: '补单',
  setBalance: '调整金额',
  failTitle: '调整订单状态失败',
  fillOrderTitle: '补单',
  setBalanceTitle: '调整金额',
  setRange: '调整范围',
  ALL: '全部',
  TRADE_ORDER: '商品订单',
  PAY_RECORD: '支付订单',
  oldMoney: '原本金额',
  afterMoney: '调整后金额',
  COMPLETED: '已完成',
  UNPROCESSED: '未处理',
  PROCESSING: '处理中',
  bankImg: '银行图片',
  bankImgPlaceholder: '请上传银行图片',
  adjustMerchantBalance: '调整银商余额',
  transferToMerchant: '转钱给银商',
  balance: '余额',
  imChannel: '聊天渠道',
  imChannelPlaceholder: '请输入聊天渠道',
  imToken: '聊天Token',
  imTokenPlaceholder: '请输入聊天Token',
  changeAmountPlaceholder: '请输入变动金额',
  userData: '用户资讯',
  merchantData: '银商资讯',
  addMoney: '增加金额',
  addMoneyPlaceholder: '请输入增加金额',
  merchantUserName: '银商用户名',
  targetUserName: '目标用户名',
  type: '类型',
  tranType: '转帐类型',
  tranToMerchant: '转帐至银商',
  tranToUser: '转帐至用户',
  applySum: '总申请金额',
  receiveSum: '总到帐金额',
  receiveGoodsSum: '商品订单总到账金额',
  receivePaySum: '支付订单总到账金额',
  appoTime: '预约时间',
  consume_typePlaceholder: '选择消费类型',
  customMoney: '自订金额',
  qaAddress: '客服位置',
  qaURL: '客服连结',
  INIT: '请求建立',
  WITHDRAWING: '申请中',
  FAIL: '失败',
  COMPLETE: '成功',
  COMPLETE_CHANGE: '已提现，但金额有异动',
  moblie: '用户手机号',
  note: '备注',
  payAmount: '交易金额',
  walletBalance: '钱包余额',
  gold: '金币',
  cumUse: '累计使用',
  cumIncome: '累计收入',
  Refund: '退款',
  gameBalance: '游戏币余额',
  APPLY: '下单时间',
  GOODS: '商品到账时间',
  PAY: '支付到账时间',
  day: '本日',
  month: '本月',
  day7: '7天',
  day30: '30天',
  day90: '90天',
  lastday: '昨日',
  lastmonth: '上月',
  lastday7: '昨7天',
  lastday30: '昨30天',
  lastday90: '昨90天',
  accountBalance: '帐户余额',
  processAmount: '处理金额数',
  completedOrder: '处理订单数',
  completedUser: '处理用户数',
  unprocessedOrder: '未处理完成单数',
  compareValue: '比較值',
  orderNumber: '订单数',
  memberNumber: '用户数',
  merchantReportExcel: '银商数据报表',
  exportData: '导出数据',
  dateRangeDay90Error: '日期自定区间不能超过90天',
  dateRangeDay31Error: '日期自定区间不能超过31天',
  dayRange90Tip: '最长筛选时间为90天',
  whiteStatus: '白名单',
  yes: '是',
  no: '否',
  detail: '详情',
  withdrawDetail: '提现详情',
  delMerchantRoleError: '银商角色无法删除',
  paymentName: '支付名称',
  paymentNamePlaceholder: '请输入支付名称',
  paymentType: '支付方式',
  paymentTypePlaceholder: '请输入支付方式',
  icon: '图标',
  uploadIcon: '上传图标',
  imageWidthHeight64Error2: '图片上传限制：图片尺寸需为64x64，图档大小需100Kb以下。',
  ConsumptionWay: '消费方式',
  ConsumptionWayPlaceholder: '请输入消费方式',
  rechargeBonusRate: '充值红利比例',
  rechargeBonusRatePlaceholder: '请输入充值红利比例',
  rechargeFixedAmount: '充值固定金额',
  rechargeFixedAmountPlaceholder: '请输入充值固定金额',
  isEnable: '是否启用',
  appRechagerAmount: 'App充值金额',
  create: '创建',
  payNamePlaceholder: '请输入支付名稱',
  paymentWayPlaceholder: '请选择支付方式',
  costTypePlaceholder: '请选择消费类型',
  costType: '消费类型',
  status: '状态',
  updater: '更新人',
  edit: '编辑',
  delete: '删除',
  delConfirmText: '此操作将永久删除该数据, 是否继续?',
  tips: '提示',
  cancel: '取消',
  cancelMsg: '已取消删除',
  cantUse: '禁用',
  oldBalance: '原始余额',
  newBalance: '新余额',
  changeAmount: '帐变金额',
  createdUsername: '建立人',
  adminChangeBalance: '管理员增减余额',
  adminToMerchant: '转帐至银商',
  merchantToMerchant: '从银商转帐',
  merchantToUser: '转帐至用户',
  activityNormal: '活动正常',
  activityInvalid: '活动过期',
  noActivity: '无活动',
  activityNoStarted: '未开始',
  discountPrice: '特价价格',
  PurchaseActivityDate: '购买次数活动日期',
  actualPrice: '实际价格',
  hasActivity: '套餐活动',
  notActivity: '非活动',
  ActivitySetting: '活动设定',
  PackageDate: '套餐上架日期',
  buyLimitCountMsg: '请选择请输入限制次数',
  goodTypeMsg: '请选择连订阅类型',
  hasActivityMsg: '请选择连活动类型',
  enterDateMsg: '请选择日期',
  verifyNotPass: '验证不通过',
  discountPriceYuanErrorMsg: '请输入实际价格',
  priceYuanErrorMsg: '请输入特价价格',
  menuNameErrorMsg: '请输入套餐名称',
  lastTimeErrorMsg: '请输入会员有效期',
  sortErrorMsg: '请输入排序',
  shopName: '店铺名称',
  shopNamePlaceholder: '请输入店铺名称',
  merchantNamePlaceholder: '请输入银商名称',
  scoreNotEmpty: '評分不能为空',
  scorePlaceholder: '请输入評分',
  monthScore: '月评价',
  monthScoreNotEmpty: '月评价不能为空',
  isOpenClose: '是否开启',
  updateSort: '更新排序',
  openSuccess: '开启成功',
  closeSuccess: '关闭成功',
  limitPurchaseTimes: '限制购买次数',
  iconPlaceholder: '请输入图标',
  imageUploadTip128: '图片上传限制：图片尺寸需为128x128，图档大小需100Kb以下。',
  shopTag: '店铺标签',
  shopTagPlaceholder: '请输入店铺标签',
  defaultIcon: '预设图示',
  filterDate: '筛选时间',
  lastPeriod: '上期',
  userID: '用户ID',
  permissionDenied: '当前操作没有权限',
  merchantOrgchart: '{msg} 组织图',
  merchant_id: '银商',
  shop_id: '店铺',
  rankList: '排行榜',
  score: '评分次数',
  avg: '平均分数',
  service_attitude: '服务态度',
  recharge_time: '充值速度',
  threeMonth: '近三个月',
  TOP: '前三名',
  LAST: '后三名',
  userScore: '用户评分表',
  merchantPlaceholder: '请选择银商',
  shopPlaceholder: '请选择店铺',
  tradeType: '交易类型',
  platformAccount: '平台账户',
  gameAccount: '游戏账户',
  accountType: '账户类型',
  actionType: '操作类型',
  addPopcoin: '加啪啪币',
  deductPopcoin: '扣啪啪币',
  rechargeCompensationOrder: '充值补单',
  rechargeFailure: '充值失败',
  rechargeAdjustAmount: '充值调整金额',
  withdrawalCompensationOrder: '提现补单',
  withdrawalFailure: '提现失败',
  withdrawalAdjustAmount: '提现调整金额',
  disableWithdrawal: '禁用提现',
  enableWithdrawal: '解禁提现',
  transferToUserSuccess: '转钱成功',
  disableGameAccount: '禁用游戏账户',
  enableGameAccount: '解禁游戏账户',
  disableWithdraw: '禁用提现',
  enableWithdraw: '解禁提现',
  gameAccountStatus: '游戏账户状态',
  withdrawStatus: '提现状态',
  disableGameAccountMsg: "是否确定禁用'{msg}'游戏账号",
  disableWithdrawMsg: "是否确定禁用'{msg}'提现",
  confirmDisable: '确认禁用',
  askList: '咨询列表',
  setTransfer: '指定转账',
  userIdRequest: '请输入用户ID',
  RECHARGE: '充值',
  RECHARGE_BONUS: '充值赠送金',
  RECHARGE_AMOUNT_CHANGE: '充值调整',
  RECHARGE_FAIL: '充值退款',
  BUY_SUBSTANCE: '购买视频',
  BUY_SVIP: '购买会员',
  GOLD_TRANSFER_GAME: '{0}转游戏币',
  WALLER_TRANSFER_GOLD: '{0}转{1}',
  GAME_TRANSFER_GOLD: '游戏币转{0}',
  WITHDRAWAL: '提现',
  WITHDRAWAL_BONUS_CHANGE: '提现赠送金调整',
  WITHDRAWAL_BONUS: '提现赠送金',
  BACKSTAGE_ADD_GOLD: '平台加{0}',
  BACKSTAGE_DEDUCTION_GOLD: '平台扣{0}',
  GAME_TRANSFER_WALLET: '游戏币转{0}',
  normal: '正常',
  banSuccess: '禁用成功',
  unlockedSuccess: '解禁成功',
  queryUserId: '查询用户ID',
  nowWalletBalance: '当前{0}余额',
  nowGameCoinBalance: '当前游戏币余额',
  recharge: '充值',
  rechargeToolTips: '充值=充值+法币购买会员',
  withdrawMoney: '提现',
  bet: '投注',
  buyVideo: '购买视频',
  loginIPHistoty: '登录IP历程',
  waitAudit: '待审核',
  auditLog: '审核纪录',
  selectWithdrawType: '选取领取状态',
  bankNoTimes: '银行卡号绑定次数',
  gameProfitLoss: '用户游戏币盈亏比例',
  deviceNoIsOk: '设备码是否正常',
  IPIsOk: 'IP地址是否正常',
  get: '领取',
  getTime: '领取时间',
  getUser: '领取人',
  backOrder: '退单',
  reject: '拒绝',
  allow: '通过',
  selectAuditAccount: '选择审核账号',
  processDoneTime: '处理完成时间',
  auditUser: '审核人',
  waitAuditDesc: '上次提现后的游戏币盈亏/转入游戏币金额-游戏币转啪啪币金额，若距上次提现超90日，则以90日为主',
  deviceDetail: '设备码详情',
  deviceNo: '设备码',
  WithdrawalerName: '提款人姓名',
  loginIP: '登录IP',
  loginIPRecent: '近一次登入IP',
  NOT_GET: '未领取',
  GET_DONE: '已领取',
  backNoDetail: '银行卡号详情',
  loginTime: '登录时间',
  loginIPDetail: '登录IP详情',
  auditStatus: '审核状态',
  auditRemark: '审核备注',
  NOT_WITHDRAWAL: '申请中',
  auditType: '审核方式',
  AUTO: '自动',
  ARTIFICIAL: '人工',
  SYSTEM: '系统',
  auditor: '选择审核人',
  chooseOperatedOption: '选择操作方式',
  SET_BALANCE: '调整金额',
  FILL_ORDER: '补单',
  operatedOption: '操作方式',
  theCurrentCardholderName: '当前持卡人姓名',
  modifyCardholderNameBankCard: '修改银行卡的持卡人名称',
  modifyNameCardman: '修改持卡人姓名',
  changeTime: '异动時間',
  pleaseSelectBankcardStatus: '请选择银行卡状态',
  all: '全部',
  effect: '有效',
  userNickname: '用户昵称',
  nameOfTheCardholderAfterModification: '修改后持卡人姓名',
  enterNameCardholderAfterModification: '请输入修改后持卡人姓名',
  modifyTheCardholderOfOtherBankCardsSimultaneously: '同步修改其他银行卡的持卡人',
  deleteAllBankCards: '删除旧银行卡并修改持卡人姓名',
  cardholderCantbeSame: '持卡人姓名相同无法修改',
  noPerms: '没有权限查询',
  membership: '会员身份',
  memberRemainingDays: '会员剩余天数',
  setSvip: '设定超级会员',
  setSvipDays: '设置超级会员天数',
  pleaseSetSvipDays: '请输入会员天数',
  settingSuccess: '设定成功',
  PAYING: '未支付',
  FAILURE: '失败',
  CANCELED: '失败',
  NOT_PAY: '待支付',
  rechargeTimeLimit: '充值时间限制',
  guideChannel: '引导渠道',
  realNameSystem: '实名制',
  uploadCertificate: '上传凭证',
  whetherToEnable: '是否启用',
  pleaseUploadImage: '请上图示',
  paymentIcon: '支付icon',
  uploadReturnOrder: '上传回执单',
  revenueAndExpenditure: '收支流水',
  paymentLimit: '支付时间限制',
  pleaseEnterLimitTime: '请输入限制时间',
  pleaseSelectGuidanceChannels: '请选引导渠道',
  minute: '分钟',
  imageWidthHeightErrorFormat: '上传图片尺寸不符合,只能是{0}x{1}!',
  imgFormatText: '尺寸：{0}*{1}\n容量：5MB以下\n格式档案：png',
  uploadImg: '上传图示',
  upPoint: '上分',
  downPoint: '下分',
  bonus: '返利',
  withdrawalBonus: '提现返利',
  rechargeBonus: '充值返利',
  addPoint: '补分',
  RECHARGE_AMOUNT_CHANGE_ADD: '充值补分',
  WITHDRAWAL_BONUS_CHANGE_ADD: '提现补分',
  deduction: '扣分',
  RECHARGE_AMOUNT_CHANGE_DEDUCTION: '充值扣分',
  WITHDRAWAL_BONUS_CHANGE_DEDUCTION: '提现扣分',
  order: '订单',
  WITHDRAWAL_FAIL_RETURN: '提现失败',
  FRIENDS_CONSUMPTION_REBATE: '好友消费返利',
  withdraw_WITHDRAWING: '处理中',
  withdraw_FAIL: '失败',
  withdraw_COMPLETE: '成功',
  withdraw_NOT_WITHDRAWAL: '申请中',
  withdrawAmountType: '提现金额类型',
  fixed: '固定',
  fixedMoneyPlaceholder: '请输入固定金额',
  amountRange: '金额范围',
  amountMinLimit: '金额最小值',
  amountMaxLimit: '金额最大值',
  withdrawAmountWay: '提现金额方式',
  fixedMoneyAndOtherMoney: '固定金额＆其他金额',
  enterAmount: '输入金额',
  withdrawSwitch: '提现开关',
  reminderTimeInterval: '催单时间间距',
  withdrawing: '出款中',
  disbursed: '已出款',
  pleaseEnter: '请输入',
  timeRange: '间距范围：1-9999',
  estimatedArrivalTime: '预计到账时间',
  normalWithdrawSwitch: '普通提现开关',
  reserveWithdrawSwitch: '预约提现开关',
  rebate: '返利',
  userInfo: '用户基本讯息',
  withdrawalMethod: '提现方式',
  pleaseSelectWithdrawalMethod: '请选择提现方式',
  selectPaymentMethod: '选择收款方式',
  paymentMethod: '收款方式',
  sdl_tunnel_id: 'SDL通道编号',
  NORMAL: '正常',
  gameId: '游戏ID',
  gameName: '游戏名称',
  gameImg: '游戏图标',
  addOne: '添加',
  gameLobby: '游戏大厅',
  deleteAllBankCard: '删除全部银行卡',
  date: '日期',
  paymentCount: '支付订单笔数',
  paymentAmount: '支付订单金额',
  withdrawCount: '提现订单笔数',
  withdrawAmount: '提现订单金额',
  transferOut: '下分',
  transferIn: '上分',
  gameToMsg: '(游戏币转{msg})',
  msgToGame: '({msg}转游戏币)',
  remainAmount: '剩余额度',
  betAmount: '投注额',
  effectBetAmount: '有效投注额',
  agShare: '代理占成',
  myProfit: '我的盈利',
  actProfit: '实际盈利',
  gameToken: '游戏彩金',
  channel: '通道',
  withdrawUserType: '提现用户类型',
  agentWithdraw: '渠道商提现',
  userWithdraw: '用户提现',
  sumPaymentCount: '总支付订单单数',
  sumPaymentAmount: '总支付订单金额',
  sumWithdrawCount: '总提现订单单数',
  sumWithdrawAmount: '总提现订单金额',
  exportWithdrawDetail: '导出提现明细',
  exportPaymentDetail: '导出支付明细',
  sumTransferOut: '总上分',
  sumTransferIn: '总下分',
  sumBetAmount: '总投注额',
  sumEffectBetAmount: '总有效投注额',
  sumMyProfit: '总我的盈利',
  sumActProfit: '总实际盈利',
  everyDayDetail: '每日详情',
  exportTransferOutDetail: '导出上分明细',
  exportTransferInDetail: '导出下分明细',
  wayReport: '通道报表',
  paymentReport: '支付明细报表',
  withdrawReport: '提现明细报表',
  gameReport: '游戏报表',
  transferOutReport: '上分明细报表',
  transferInReport: '下分明细报表',
  NumberOfDays: '天数',
  permanent: '永久',
  pleaseEnterDistributorAccount: '请输入渠道商账号',
  pleaseEnterGoodsOrderNum: '请输入商品订单号',
  withdrawCardNo: '提款卡号',
  ARRIVAL_TIME: '到账时间',
  operationLog: '操作日志',
  distributorWithdrawOperationLog: '渠道商提现操作日志',
  distributorWithdrawSetting: '渠道商提现设定',
  withdrawalAmountRange: '提现金额区间',
  withdrawalMoneyPlaceholder: '请输入提现金额',
  accountStatus: '账号状态',
  disabled: '禁用',
  WITHDRAWAL_CHANGE: '提现金额调整',
  returnGameToken: '返游戏彩金',
  returnGameTokenErrorMsg: '请输入返游戏彩金',
  memberPackageIsEnable: '会员套餐启用中，不可删除',
  gameTokenBalance: '游戏彩金余额',
  addGameToken: '加游戏彩金',
  deductGameToken: '扣游戏彩金',
  gameTokenCount: '游戏彩金数',
  officialCertification: '官方认证',
  batchDisabledAccountContent: '是否确定禁用{0}个账号?',
  batchEnabledAccountContent: '是否确定解禁{0}个账号?',
  serviceTitlePlaceholder: '限20个字',
  disableAccountNumber: '批量禁用账号',
  batchLiftingAccount: '批量解禁账号',
  customerService: '客服通知',
  CustomerServiceNotificationTitle: '客服通知标题',
  disableAccount: '禁用账号',
  liftingAccount: '解禁账号',
  resetPassword: '重置密码',
  setMobilePhoneNumber: '设置手机号',
  setAvatar: '设置头像',
  setTheNickname: '设置昵称',
  userDetailMobile: '手机号',
  registrationTime: '注册时间',
  age: '年龄',
  gender: '性别',
  area: '地区',
  numberOfReports: '被举报数',
  male: '男',
  female: '女',
  secrecy: '保密',
  disableUserMsg: "是否确定禁用'{msg}'账号",
  resetPasswordSuccess: '重置密码成功',
  nickNamePlaceholder: '请输入昵称',
  nickNameMinString: '昵称长度不能少于2个字符',
  nickNameMaxString: '昵称长度不能大于8个字符',
  copy: '复制',
  copySuccess: '复制成功',
  mustSelectOne: '请选一项',
  noLoginDay: '未登入天数',
  pleaseInputMobile: '请输入登录手机号',
  mobileErrorMsg: '手机号格式不正确',
  previewAvatar: '头像预览',
  imgSameScaleTip: '请上传宽高比为1 : 1的文件',
  proxyDataOfWebsite: '全民代理网站数据',
  numberOfVisitors: '网站开启数',
  downloadTimes: '点击下载次数',
  numberOfNewUsers: '推广码下载新增用户数',
  currentRatio: '当前分成占比',
  subscribersCount: '充电用户数',
  gameRevenue: '游戏收入',
  currencyType: '币种',
  sourcesOfFunds: '资金来源',
  aiDataSetting: 'A I 辅助生成数据设置',
  aiScheduleSetting: 'A I 辅助生成排程设置',
  minValue: '最小值',
  maxValue: '最大值',
  switch: '开关',
  closeWhenOverThreshold: '原数据超越此值则自动跳过执行',
  to: '至',
  thresholdPlaceholder: '1-999999',
  SUBSTANCE_COMMENT: '评论数',
  SUBSTANCE_LIKE: '点赞数',
  SUBSTANCE_PLAY: '观看数',
  SUBSTANCE_COLLECT: '收藏数',
  SUBSTANCE_DOWNLOAD: '下载数',
  SUBSTANCE_FORWARD: '转发数',
  everyDay: '每日',
  H: '时',
  M: '分',
  or: '或',
  every: '每',
  everyTime: '每次',
  count: '笔',
  repeatImplement: '重复执行',
  hourList: '0-23',
  minuteList: '0-59',
  minMaxDataError: '范围最大值不能小于最小值',
  overThresholdError: '最小与最大值不可大于自动关闭栏位值',
  aiDataSettingTips1:
    '1.相关次数将在两个数值间距中随机产生一个数值,且可自行选择开启或关闭某一种数值<br/>2.单位与数量<br/>1-999999 不可为0或负数,空位<br/>3.且最小与最大值不可大于自动关闭栏位值',
  aiScheduleSettingTips1: '单选勾选开关,二择一<br/>下拉選單<br/>时间为0-23时,与0-59分',
  aiScheduleSettingTips2: '单位与数量<br/>1-{num}笔 不可为0或负数,空位',
  aiScheduleSettingTips3:
    '此开关开启时 可以重复执行辅助生成设置中所有数据且已执行过的视频,关闭则不重复执行',
  aiScheduleSettingWarning: '单位与数量 1-{num}笔 不可为0或负数,空位',
  alreadySend: '已发送',
  waitSend: '未发送',
  cancelSend: '取消发送',
  imgSize363x150: '尺寸：363*150 容量：5m以下 格式档案：png',
  imgSize363x150HTML: '尺寸：363*150<br>容量：5m以下<br>格式档案：png',
  addSuccess: '操作成功',
  sendMessage: '发送消息',
  userMessage: '用户消息',
  enterMessageTitle: '请输入消息标题',
  selectMessageStatus: '选择消息状态',
  pushTime: '推送时间',
  messageTitle: '消息标题',
  sender: '发送人',
  targetUser: '目标用户',
  messageStatus: '消息状态',
  readRate: '读取率',
  checkIt: '查看',
  content: '内容',
  imgIcon: '图示',
  graphicJump: '图示跳转',
  selectGraphicJump: '选择图示跳转',
  messageType: '消息类别',
  systemMessage: '系统消息',
  allUsers: '全体用户',
  superVipUsers: '超级会员用户',
  partialUsers: '部分用户',
  enterPartialUserID: '请输入部分用户ID',
  selectedUserCount: '目前选择用户数量为: ',
  batchUpload: '批量上传',
  downloadExample: '下载范例',
  push: '推播',
  messageCenterNotification: '消息中心通知',
  pushImmediately: '立即推送',
  schedulePush: '预约推送',
  scheduleTime: '预约时间',
  selectDateAndTime: '选择日期选择时间',
  noRedirect: '不转跳',
  downloadList: '下载名单',
  immediately: '立刻',
  scheduled: '定时',
  pleaseInputContent: '請輸入內容',
  pushWay: '推送方式',
  pleaseInputLink: '请输入链结',
  pleaseInputPushWay: '选择推送方式',
  onlyUploadXLSX: '只能上传后缀是.xlsx',
  pleaseInputMsgType: '选择消息类型',
  pleaseInputReadStatus: '选择读取状态',
  msgType: '消息类型',
  msgContent: '消息内容',
  readAndUnread: '已读/未读',
  previewMsg: '消息预览',
  more: '更多',
  KEFU: '客服',
  isReaded: '已读',
  unread: '未读',
  messageCenterNotificationAndPush: '推播、消息中心通知',
  imgLink: '图示连结',
  clickDownload: '点击下载名单',
  other: '其他',
  noNowTime: '不可设定当下时间',
  sendList: '發送名單',
  parameterName: '参数名称',
  parameterValue: '参数值',
  logoutDays: '长时间未登录天数',
  logoutDaysCondition:
    '该参数控制提现审核长时间未登录异常条件，最大值3650日，输入0等于该判断关闭。',
  suspectLists: '提款姓名嫌疑名单',
  suspectListsCondition: '该参数控制提现审核提款人姓名异常条件',
  dayUnit: '天',
  pleaseEnterLogoutDays: '请输入长时间未登录天数',
  payName: '付款人姓名',
  phoneNumber: '手机号码',
  loginAreaIsNormal: '登录地区是否正常',
  logoutForLongTime: '长时间未登录',
  paymentOrderIsNormal: '支付订单是否正常',
  withdrawerDetail: '提款人姓名详情',
  loginAreaDetail: '登录地区详情',
  sinceLastLoginTime: '距离上次登录时长',
  paymentOrderDetail: '支付订单详情',
  lastTenApplication: '近10次申请总额',
  lastTenDeposits: '近10次到账总额',
  lastTenArrivals: '近10次到账率',
  enableThisVersion: '启用此版本',
  downloadWebsite: '下载网址',
  abandoned: '废弃',
  clickPercent: '点击率',
  installPercent: '安装率',
  downloadAddUser: '下载新增用户数',
  officalData: '官方推广页数据',
  installTechData: '安装教学页数据',
  inviteSumData: '推广總数据',
  selectDate: '选择时间',
  paymentOrderIsNormalTips: '不正常定义：近10次充值到账率低于20%或付款人姓名不同者',
  selectMessageType: '选择消息类型',
  chooseASender: '选择发送人',
  manual: '手动',
  platformAnnouncement: '平台公告',
  nationalAgent: '全民代理',
  gamingAgent: '游戏代理',
  thread: '提现消息',
  rechargeMessage: '充值消息',
  gamingAgentIdentity: '游戏代理身份',
  proxyIdentity: '代理身份',
  superiorAgentID: '上级代理ID',
  agentBindingTime: '代理绑定时间',
  totalNumberOfLowerAgents: '下级代理总数量',
  directSubUserNumber: '直属下级数量',
  directUserNumber: '直属用户数量',
  signedContracts: '已签约数量',
  notSetContracts: '未设置契约数量',
  awaitingContracts: '待签约数量',
  levelOne: '一级代理',
  levelTwo: '二级代理',
  levelThree: '三级代理',
  levelFour: '四级代理',
  levelFive: '五级代理',
  directUser: '直属用户',
  frequency: '次',
  domainNamePosition: '域名位置',
  domainNameAddress: '域名地址',
  pleaseEnterDomainName: '请输入域名',
  newAgent: '新增代理',
  promotionLink1: '推广链接1',
  promotionLink2: '推广链接2',
  pleaseEnterDomainName2: '请输入{0}的域名',
  abandonedHistorical: '废弃历史清单',
  appDomainBame: 'App域名',
  abandon: '废弃',
  maxTenItems: '最多仅创建10条项目',
  maxHundredItems: '最多仅创建100条项目',
  noAbandonInEnabled: '启用状态下网域不可进行废弃',
  domainReplaceMessage: '输入网域与废弃历史清单内重叠，请输入其他网域',
  isAbandonDomain: '{0}为废弃网域',
  resetSuccess: '重置成功',
  abandonDomain: '废弃网域',
  abandonTime: '废弃时间',
  rechargeToGetSvip: '充值送SVIP',
  singleRechargeFull: '单笔充值满',
  sendMember: '送会员',
  isNoLessThan: '大于等于',
  isLessThan: '小于',
  rechargeToGetSvipTips:
    '数字须为0-999999，不可重叠、充值栏位数目区间前者不可大于后者，不可输入负数、空位、符号。',
  membershipDetails: '会员身份详情',
  querySource: '查询来源',
  source: '来源',
  obtainTime: '取得时间',
  domainName: '域名',
  firstRechargeBonus: '首次充值优惠',
  firstBonusRatio: '首充红利比例',
  firstBonusRatioPlaceholder: '请输入首充红利比例',
  firstBonusContent: '首充文案内容',
  firstBonusContentPlaceholder: '请输入首充文案内容',
  taskRewardConfig: '任务奖励配置',
  openMission: '任务开启',
  closeMission: '任务关闭',
  SVIP: '超级会员',
  GAME_COIN: '游戏彩金',
  VIDEO_CARD: '观影券',
  giftDays: '赠送天数',
  giftBonus: '赠送彩金',
  giftCoupons: '赠送券数',
  saveConfig: '保存配置',
  webAndMailConfig: '任务网域＆邮件配置',
  addMission: '新增任务',
  mission: '任务',
  missionTitle: '任务标题',
  missionSubtitle: '任务副标',
  domain: '网域',
  buttonText: '按钮文字',
  sendMailMessage: '寄送邮件讯息',
  clickLinkMission: '点击连结任务',
  sendMailMission: '邮箱寄送任务',
  saveOfficialLinkMission: '官方连结保存任务',
  nWordLimit: '仅限{0}个字元',
  pleaseEnterValidUrl: '请输入有效网址',
  fourWorldLimit: '仅限4个字元',
  maximumOfFiveMissions: '任务最多建立5个',
  atLeastOpenOneAward: '请至少开启一个奖励',
  pleaseSelectMissionType: '请选择任务类型',
  questionnairePlace: '问卷位置',
  questionnaireTitle: '问卷标题',
  questionnaireType: '问卷类型',
  questionnaireContent: '问卷内容',
  statistics: '统计',
  closingTime: '关闭时间',
  questionnaireEdit: '问卷调查编辑',
  questionnaireStatistics: '问卷统计',
  whetherToDelete: '是否确定删除?',
  otherReasons: '其他原因',
  onlyOneSelect: '单选',
  multipleSelect: '复选',
  questionnairePositionType1: '充值-取消订单',
  questionnairePositionType2: '提现-取消订单',
  details: '详请',
  startingTime: '起迄时间',
  participantNum: '参与统计次数',
  questionNature: '问题性质',
  select: '选择',
  numberOfChoices: '选择次数',
  questionnaireTitlePlaceholder: '请输入问卷名称，限10个字',
  questionNatureToolTips:
    '自行输入：只能选1个,选第2个的提示语：自行输入只能选1个,自行输入不是最后1项时，保存后自动调整至最后1个，其它依序往上移。',
  cancelTypePlaceholder: '限15个字',
  questionnaireErrorMsg1: '启用中的问卷无法删除',
  questionnaireErrorMsg2: '请输入问题内容',
  time: '时间',
  percentage: '占比(%)',
  firstRechargeFeedbackTips:
    '数字须为1-999999，不可重叠、充值栏位数目区间前者不可大于后者，不可输入负数、空位、符号。',
  confirmSave: '确认设置',
  firstChargerSettings: '首充优惠设定',
  RechargeIncreaseMoneyFeedbackSettings: '充值增金回馈设定',
  giftMoney: '赠金',
  advantageIncreasedGoldDiscount: '充值增金优惠',
  registrationSource: '注册来源',
  promotionCode: '推广码',
  equipmentInfo: '设备资讯',
  ip: 'IP地址',
  deviceNumber: '设备号码',
  assetInfo: '资产资讯',
  ticketsRemainder: '观影券余數',
  integralRemainder: '积分余数',
  accountInfo: '帐务资讯',
  accumulatedVipPurchases: '累计购买VIP',
  accumulatedRecharge: '累计充值{0}',
  accumulatedGameCoinExchange: '累计游戏币兑换',
  accumulatedWithdraw: '累计{0}提现',
  accumulatedGameCoinConsumption: '累计游戏彩金消耗',
  accumulatedUseOfTickets: '累计观影券使用',
  deviceModel: '设备型号',
  deviceSystem: '设备系统',
  agentInfo: '代理资讯',
  nationalAgentLevel: '全民代理等级',
  directSuperiorID: '游戏上级代理ID',
  agentBindingTime: '游戏代理绑定时间',
  gameAgentIdentity: '游戏代理身份',
  videoRecommendationTendencySetting: '视频推荐倾向设定',
  popularDescription: '*数值越小，推荐大众浏览视频越多。',
  specializationDescription: '*数值越高，推荐更专注于用户互动下的标签视频越多。',
  popular: '热门大众化',
  specialization: '各人专属化',
  videoRecommendationWeightSetting: '视频推荐权重设定',
  weightSettingDescription: '*请输入介于1.0 ~ 2.0之间的数字，不能为空值。',
  userBehavior: '用户行为',
  FAVORITE: '用户喜好',
  INTERACT: '用户互动',
  weightContent: '权重内容',
  VIDEO_WATCH_TIME: '视频观看时间',
  CLICK_VIDEO_TAG: '点击视频标签',
  ADD_TAG: '增加自定义标签',
  VIDEO_LIKE: '视频点赞',
  VIDEO_COLLECT: '视频收藏',
  VIDEO_DISCUSS: '视频评论',
  DANMU_MESSAGE: '弹幕留言',
  weightedProportion: '加权比重',
  weightPlaceholderText: '1.0 ~ 2.0',
  confirmSetting: '确认设置',
  passwordRule: '需包含大、小写字母、数字、特殊符号，长度8-16位',
  promotionLinkTemplate: '推广连结模板',
  landingPageTemplate: '落地页模板',
  openOrNot: '是否开放',
  promotionLinkLandingPage: '推广连结落地页',
  promotionalLink: '推广连结',
  getLink: '获取连结',
  getQRCode: '获取二维码',
  noData: '暂无数据',
  CLICK_VIDEO: '点击视频',
  parameter: '参数',
  gameAgentSetting: '游戏代理设置',
  setAgentIdentity: '设定代理身份',
  setAgentContract: '设定代理契约',
  settingMethodTips: '设置方式：由最小值到最大值',
  serialNumberOfRule: '规则序号',
  dividedRatio: '分成比例(%)',
  netWinLossOfMonth: '当月净输赢',
  netWinLossMaximumTips: '无限大=999999999',
  minimumRequirementsForActiveUsers: '活跃用户最低要求',
  activeUsersDefinition: '活跃用户定义：当月充值>=100 或有效投注>=500',
  maximum10: '最多10笔',
  noSmallerThan: '不可比规则{index}小',
  overlappingWith: '与规则{index}重迭',
  noEmpty: '不可为空',
  agentTreeMigration: '代理树迁移',
  agentIdentity: '代理身份',
  superiorAgentIDBeforeModify: '修改前上级代理ID',
  superiorAgentIDAfterModify: '修改后上级代理ID',
  pleaseEnterSuperiorID: '请输入上级ID',
  agentTreeMigrationTips: '提醒：上级更换后，所有下级契约需重新签约。',
  gameAgentContractDetail: '游戏代理契约明细',
  contractContent: '契约内容',
  sendContractTime: '发送契约时间',
  contractConfirmTime: '契约确认时间',
  numberOfPeople: '人数',
  numberOfRule: '规则{index}',
  winOrLose: '输赢',
  numberOfActivePeople: '活跃人数',
  divided: '分成',
  applicantID: '申请人ID',
  APPLICATION_TIME: '申请时间',
  REVIEW_TIME: '审核时间',
  reviewResult: '审核结果',
  hasSuperior: '是否有上级',
  applicantNickname: '申请人昵称',
  applicationPassed: '申请通过',
  beRejectedTips: '该用户曾申请过被拒绝，请确定是否要通过',
  addGameCommission: '加游戏佣金',
  deductGameCommission: '扣游戏佣金',
  getGameAgentPromotionLink: '获取游戏代理推广链结',
  gameCommissionBalance: '游戏佣金余额',
  gameCommission: '游戏佣金',
  gameCommissionAccount: '游戏佣金账户',
  nowGameommissionBalance: '当前游戏佣金余额',
  auditRecords: '审核记录',
  chooseMonth: '请选择月份',
  searchAgentID: '查询代理ID',
  agentID: '代理ID',
  agentNickname: '代理昵称',
  Month: '月份',
  agentNetProfit: '代理冲正净输赢',
  rebateRatio: '返佣比例(%)',
  teamCommission: '团队佣金',
  personalCommission: '个人佣金',
  lowerLevelTotalCommission: '下级总佣金',
  modifyPersonalCommission: '调整个人佣金',
  beforeModify: '调整前',
  afterModify: '调整后',
  remarkLimitHundredWords: '备注限100个字',
  personalCommissionDetail: '个人佣金详情',
  modifyTime: '调整时间',
  operatedAccount: '操作账号',
  onlyShowTheLastTenRecords: '仅显示近10次记录',
  COMMISSION_TRANSFER_OTHERS: '佣金转{0}',
  PLATFORM_TRANSFER_IN: '平台转入',
  SUPERIOR_TRANSFER_IN: '上级转入',
  gameCoin: '游戏币',
  PAY_TO_SUB_AGENT: '支付下级',
  routePathValidateMessage: '路径开头应为 cy.',
};
