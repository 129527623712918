import { getExtensionWithdrawOrderCount } from '@/api/distributor-manage/distributor-withdrawAudit';
import store from '@/store';

const distributorManage = {
  state: {
    extensionWithdrawOrderCount: 0,
    distrMobile: '',
  },
  actions: {
    async GetExtensionWithdrawOrderCount({ commit }) {
      if (store.getters.permissions.distributor_withdrawAudit_search) {
        const { data } = await getExtensionWithdrawOrderCount({});
        commit('SET_EXTENSION_WITHDRAW_ORDER_COUNT', data.data);
      }
    },
  },
  mutations: {
    SET_EXTENSION_WITHDRAW_ORDER_COUNT: (state, count) => {
      state.extensionWithdrawOrderCount = count;
    },
    SET_DISTR_MOBILE(state, payload) {
      state.distrMobile = payload;
    },
  },
};
export default distributorManage;
