export default {
  // ---------route------------------------
  '/user/details/index': 'Member Details',
  '/admin/user/index': 'Backend Account Management',
  '/admin/role/index': 'Role and Permissions Settings',
  '/admin/securityCenter/index': 'Authentication Mechanism Settings',
  '/user/userList/index': 'Member Management',
  '/business-manage/memberList/index': 'Super Member List',
  '/business-manage/paymentList/index': 'Payment Channel Settings',
  '/business-manage/bankCard/index': 'Bank Card Settings',
  '/withdraw/config/index': 'Withdrawal Settings',
  '/merchant': 'Merchant Management',
  '/merchant/paymentMethodList/index': 'Merchant Payment Method List Settings',
  '/merchant/merchantList/index': 'Merchant List',
  '/merchant/shopList/index': 'Shop List',
  '/merchant/iconList/index': 'Pre-set Icon List',
  '/merchant/record/index': 'Transfer Transaction Records',
  '/merchant/balanceChangeDetail/index': 'Merchant Balance Change Records',
  '/gameCenter': 'Game Center',
  '/gameCenter/category/index': 'Game Categories',
  '/gameCenter/game/index': 'Game Listing',
  '/gameCenter/banner/index': 'Game Page Advertising',
  '/merchant/report/index': 'Merchant Data Dashboard',
  '/merchant/ask/index': 'Consult Transfer List',
  '/record': 'Record Manage',
  '/record/orderPaymentList/index': 'Payment Order Inquiry',
  '/record/withdraw/index': 'Withdrawal Record Query',
  '/admin/qaSetting/index': 'Customer Service Settings',
  '/business-manage/membershipSet/index': 'Membership Packages',
  '/merchant/bankerRating/index': 'Merchant Banker Rating',
  '/record/tradeDetail/index': 'Funds Transaction Details',
  '/user/actionLog/index': 'Action Log',
  '/record/withdrawAudit/index': '提现审核',
  '/record/userFundsOverview/index': 'User Funds',
  '/distributor-manage/distributor-withdrawAudit/index': 'Channel merchant withdrawal review',

  // Game Center
  addCategory: 'Add Category',
  categoryName: 'Category Name',
  categoryIcon: 'Category Icon',
  categorySort: 'Category Sorting',
  isTop: 'Display on Homepage List',
  open: 'Open',
  close: 'Close',
  confirm: 'Confirm',
  categoryTip1: '1. Only 5 categories can be enabled for display on the App game page.',
  categoryTip2:
    "2. Each category must have a multiple of 4 games to be displayed on the App. Up to 8 games can be shown. If more than 8, a 'More' button will appear.",
  searchGameName: 'Search Game Name',
  searchGameCategeory: 'Search Game Category',
  reset: 'Reset',
  add: 'Add',
  serialNumber: 'Serial Number',
  gameIcon: 'Game Icon',
  gameTitle: 'Game Title',
  gameTitlePlaceholder: 'Enter Game Title',
  gameDesc: 'Game Description',
  gameDescPlaceholder: 'Enter Game Description',
  gameCategeory: 'Game Category',
  gameBetMultiplier: 'Game Bet Multiple',
  gameCategeoryPlaceholder: 'Select Game Category',
  gameTagSetting: 'Game Tag Setting',
  gameTagSettingPlaceholder: 'Enter Tag Setting',
  gameLink: 'Game Link',
  gameLinkMsg: 'Enter Game Link',
  gameWeight: 'Game Weight',
  gameWeightPlaceholder: 'Enter Weight Value 1~100',
  gameCategoryTip1:
    "Game weight setting affects the game's ranking in each category form. 1 is the highest, followed by descending order.",
  showInHotGame: 'Show in Hot Games',
  gameCategoryTip2:
    "Hot games need to be enabled with at least 8 games to be displayed on the App's hot rankings. Less than 8 will not be displayed.",
  operate: 'Operate',
  delDialogMsg1: 'Are you sure you want to delete this game',
  delDialogConfirmText: 'Confirm Delete',
  delDialogCancelText: 'Cancel',
  deleteSuccess: 'Delete Successful',
  editSuccess: 'Edit Successful',
  imgOnlyPng: 'Uploaded images must be in PNG format!',
  gameCategoryModalMsg:
    'There are still published games in this game category. Are you sure you want to delete the entire category of games?',
  categorySortPlaceholder: 'Enter Category Sorting',
  categoryNamePlaceholder: 'Enter Category Name',
  categoryIconUrlPlaceholder: 'Upload Category Icon',
  successCreate: 'Create Successful',
  advertName: 'Ad Name',
  startPlaceholder: 'Start Date',
  endPlaceholder: 'End Date',
  advertState: 'Ad State',
  enable: 'Enable',
  disable: 'Disable',
  advertImg: 'Ad Image',
  advertSort: 'Ad Sorting',
  startTime: 'Start Time',
  endTime: 'End Time',
  forwardUrl: 'Redirect URL',
  creater: 'Creator',
  delDialogMsg2: 'Are you sure you want to delete this game page ad',
  advertNamePlaceholder: 'Enter Ad Name',
  advertImgUploadTip: 'Size: 990 * 450 Format: png',
  addedTime: 'Published Time',
  addedTimePlaceholder: 'Enter Published Time',
  advertSortPlaceholder: 'Enter Ad Sorting',
  forwardType: 'Redirect Type',
  outsideLink: 'External Link',
  insideLink: 'Internal Link',
  forwardUrlPlaceholder: 'Enter Redirect URL',
  advertIconUrlPlaceholder: 'Upload Ad Image',
  gameIconPlaceholder: 'Upload Game Icon Image',

  // Withdrawal
  queryUsername: 'Search User Nickname',
  queryPhone: 'Search Phone Number',
  moneyRangeMin: 'Minimum Application Amount',
  moneyRangeMax: 'Maximum Application Amount',
  actionStartTime: 'Application Start Time',
  actionEndTime: 'Application End Time',
  goodsOrderNum: 'Goods Order Number',
  paymentOrderNum: 'Payment Order Number',
  withdrawBank: 'Withdrawal Bank',
  bankCardNo: 'Bank Card Number',
  query: 'Search',
  bankPlaceholder: 'Select Withdrawal Bank',
  withdrawTime: 'Select Withdrawal Time',
  statusPlaceholder: 'Select Status',
  withdrawUserName: 'Withdrawal Name',
  applicationTime: 'Withdrawal Time',
  applicationMoney: 'Application Amount',
  arrivalTime: 'Arrival Time',
  arrivalMoney: 'Arrival Amount',
  extraMoney: 'Bonus',
  goods: 'Goods',
  tradeMoney: 'Trade Gold Coins',
  tradeAfterMoney: 'Balance After Transaction',
  orderName: 'Order Name',
  tradeResult: 'Transaction Result',
  tradeTime: 'Transaction Time',
  bankName: 'Bank Name',
  cardName: 'Cardholder Name',
  bindTime: 'Bind Time',
  bankCardDetail: 'Bank Card Details',
  plus: 'Add',
  decrease: 'Deduct',
  remark: 'Remark',
  remarkPlaceHolder: 'Enter Remark',
  moneyPlaceHolder: 'Enter Amount',
  withdrawName: 'Withdrawal Name',
  limitType: 'Limit Type',
  limitTypePlaceholder: 'Enter Limit Type',
  limitRange: 'Limit Amount Range',
  limitValue: 'Fixed Limit Amount',
  minLimit: 'Minimum Limit Amount',
  maxLimit: 'Maximum Limit Amount',
  limitValuePlaceholder: 'Enter Fixed Limit Amount',
  withdrawBonus: 'Refucement ratio of withdrawal',
  withdrawBonusPlaceholder: 'Enter Withdrawal Bonus Percentage',
  activityContent: 'Activity Content',
  contentPlaceholder: 'Enter Activity Content',
  specialPoint: 'Special Points',
  specialPointPlaceholder: 'Enter Special Points',
  withdrawFixedMoney: 'Withdrawal Fixed Amount',
  withdrawFixedMoneyPlaceholder: 'Enter Withdrawal Fixed Amount',
  normalContent: 'Ordinary withdrawal title',
  normalContentPlaceholder: 'Please enter the general withdrawal title',
  reserveContent: 'Appointment withdrawal title',
  reserveContentPlaceholder: 'Please enter the title of appointment withdrawal',
  resTimeAndBonus: 'Reservation Time Limit and Bonus',
  reserve: 'Reservation',
  hour: 'Hour',
  extraBonus: 'Bonus',
  withdrawNamePlaceholder: 'Enter Withdrawal Name',
  imgSize64: 'Size: 64*64 Capacity: Below 100KB Format: png',
  imgSize72: 'Size: 72*72 Capacity: Below 100KB Format: png',
  imgSize128: 'Size: 128*128 Capacity: Below 100KB Format: png',
  imgSize192: 'Size: 192*192 Capacity: Below 100KB Format: png',
  clickUpload: 'Click to Upload',
  appoSetting: 'Appointment',
  timeLimit: 'Time Limit',
  timeLimitPlaceholder: 'Please enter the appointment time limit',
  give: 'Give',
  pleaseEnterRebateRatio: 'Please enter the rebate ratio',
  giveTip: 'Up to 9 gifts can be added',
  appWithdrawMoney: 'App Withdrawal Amount',
  limitFixedPreview: 'Fixed Amount Menu (Preview)',
  customRange: 'Enter Range',
  fixedMoney: 'Fixed Amount',
  rangeMoney: 'Range',
  minMaxValueError: 'Maximum value for range cannot be less than minimum value',
  appoSettingError: 'Appointment settings time limit or gift value not filled in completely',
  imageLimitUploadError:
    'Only one image can be uploaded. Please delete the already uploaded image before uploading a new one',
  imageTypeJpgPngError: 'Uploaded image must be in JPG/PNG format',
  imageTypePngError: 'Uploaded image must be in PNG format',
  imageSizeError: 'Uploaded image size cannot exceed 100KB',
  imageSizeError2: 'Uploaded image size cannot exceed 5MB',
  imageWidthHeight64Error: 'Uploaded image size must be 64x64 pixels',
  imageWidthHeight72Error: 'Uploaded image size must be 72x72 pixels',
  imageWidthHeight128Error: 'Uploaded image size must be 128x128 pixels',
  imageWidthHeight192Error: 'Uploaded image size must be 192x192 pixels',
  imageWidthHeight990x450Error: 'Uploaded image size must be 990x450 pixels',
  withdrawTitle: 'Withdrawal Method',
  moneyTip: 'Up to 11 items, e.g., 100, 200, 300, etc.',
  record: 'Record',
  transferMoney: 'Transfer Money',
  done: 'Done',
  userName: 'User Name',
  merchantName: 'Merchant Name',
  askStatus: 'Inquiry Status',
  createTime: 'Creation Time',
  updateTime: 'Update Time',
  selectTimeType: 'Select Time Category',
  applyMoneyMin: 'Minimum Amount to be Received',
  applyMoneyMax: 'Maximum Amount to be Received',
  selectOrderStatus: 'Select Product Order Status',
  selectPayType: 'Select Payment Method',
  selectPayStatus: 'Select Payment Order Status',
  bankCode: 'Bank ID',
  Operator: 'Operator',
  OperatorTime: 'Operation Time',
  bankCodePlaceholder: 'Enter Bank ID',
  bankNamePlaceholder: 'Enter Bank Name',
  goodsArrivalTime: 'Product Arrival Time',
  goodsarrivalMoney: 'Product Arrival Amount',
  payArrivalTime: 'Payment Arrival Time',
  payArrivalMoney: 'Payment Arrival Amount',
  goodOrderStatus: 'Product Order Status',
  payOrderStatus: 'Payment Order Status',
  fillOrder: 'Fill Order',
  setBalance: 'Adjust Amount',
  failTitle: 'Failed to Adjust Order Status',
  fillOrderTitle: 'Fill Order',
  setBalanceTitle: 'Adjust Amount',
  setRange: 'Adjust Range',
  ALL: 'All',
  TRADE_ORDER: 'Product Order',
  PAY_RECORD: 'Payment Order',
  oldMoney: 'Original Amount',
  afterMoney: 'Adjusted Amount',
  COMPLETED: 'Completed',
  UNPROCESSED: 'Unprocessed',
  PROCESSING: 'Processing',
  bankImg: 'Bank Image',
  bankImgPlaceholder: 'Upload Bank Image',
  adjustMerchantBalance: 'Adjust Merchant Balance',
  transferToMerchant: 'Transfer to Merchant',
  balance: 'Balance',
  imChannel: 'Chat Channel',
  imChannelPlaceholder: 'Enter Chat Channel',
  imToken: 'Chat Token',
  imTokenPlaceholder: 'Enter Chat Token',
  changeAmountPlaceholder: 'Enter Change Amount',
  userData: 'User Information',
  merchantData: 'Merchant Information',
  addMoney: 'Add Amount',
  addMoneyPlaceholder: 'Enter Add Amount',
  merchantUserName: 'Merchant Username',
  targetUserName: 'Target Username',
  type: 'Type',
  tranType: 'Transfer Type',
  tranToMerchant: 'Transfer to Merchant',
  tranToUser: 'Transfer to User',
  applySum: 'Total Applied Amount',
  receiveSum: 'Total Received Amount',
  receiveGoodsSum: 'Total Received Amount for Product Orders',
  receivePaySum: 'Total Received Amount for Payment Orders',
  appoTime: 'Appointment Time',
  consume_typePlaceholder: 'Select Consumption Type',
  customMoney: 'Custom Amount',
  qaAddress: 'Customer Service Location',
  qaURL: 'Customer Service Link',
  INIT: 'Request Initiation',
  WITHDRAWING: 'In Process',
  FAIL: 'Failed',
  COMPLETE: 'Successful',
  COMPLETE_CHANGE: 'Withdrawn, but Amount has Changed',
  moblie: 'User Mobile Number',
  note: 'Note',
  payAmount: 'Transaction Amount',
  walletBalance: 'Wallet Balance',
  gold: 'Gold Coins',
  cumUse: 'Total Usage',
  cumIncome: 'Total Income',
  Refund: 'Refund',
  gameBalance: 'Game Coin Balance',
  APPLY: 'Application Time',
  GOODS: 'Product Arrival Time',
  PAY: 'Payment Arrival Time',
  day: 'Today',
  month: 'This Month',
  day7: '7 Days',
  day30: '30 Days',
  day90: '90 Days',
  lastday: 'Yesterday',
  lastmonth: 'Last Month',
  lastday7: 'Last 7 Days',
  lastday30: 'Last 30 Days',
  lastday90: 'Last 90 Days',
  accountBalance: 'Account Balance',
  processAmount: 'Processed Amount',
  completedOrder: 'Processed Orders',
  completedUser: 'Processed Users',
  unprocessedOrder: 'Unprocessed Orders',
  compareValue: 'Comparison Value',
  orderNumber: 'Order Number',
  memberNumber: 'User Number',
  merchantReportExcel: 'Merchant Data Report',
  exportData: 'Export Data',
  dateRangeDay90Error: 'Date custom range cannot exceed 90 days',
  dateRangeDay31Error: 'Date custom range cannot exceed 31 days',
  dayRange90Tip: 'Maximum filter time is 90 days',
  whiteStatus: 'White List Status',
  yes: 'Yes',
  no: 'No',
  detail: 'Details',
  withdrawDetail: 'Withdrawal Details',
  delMerchantRoleError: 'Merchant role cannot be deleted',
  paymentName: 'Payment Name',
  paymentNamePlaceholder: 'Enter Payment Name',
  paymentType: 'Payment Method',
  paymentTypePlaceholder: 'Enter Payment Method',
  icon: 'Icon',
  uploadIcon: 'Upload Icon',
  imageWidthHeight64Error2:
    'Image upload limit: Image size must be 64x64, file size must be below 100KB.',
  ConsumptionWay: 'Consumption Method',
  ConsumptionWayPlaceholder: 'Enter Consumption Method',
  rechargeBonusRate: 'Recharge Bonus Rate',
  rechargeBonusRatePlaceholder: 'Enter Recharge Bonus Rate',
  rechargeFixedAmount: 'Recharge Fixed Amount',
  rechargeFixedAmountPlaceholder: 'Enter Recharge Fixed Amount',
  isEnable: 'Is Enabled',
  appRechagerAmount: 'App Recharge Amount',
  create: 'Create',
  payNamePlaceholder: 'Enter Payment Name',
  paymentWayPlaceholder: 'Select Payment Method',
  costTypePlaceholder: 'Select Consumption Type',
  costType: 'Consumption Type',
  status: 'Status',
  updater: 'Updater',
  edit: 'Edit',
  delete: 'Delete',
  delConfirmText: 'This action will permanently delete the data. Do you want to continue?',
  tips: 'Tips',
  cancel: 'Cancel',
  cancelMsg: 'Deletion canceled',
  cantUse: 'Disabled',
  oldBalance: 'Original Balance',
  newBalance: 'New Balance',
  changeAmount: 'Account Change Amount',
  createdUsername: 'Creator',
  adminChangeBalance: 'Admin Change Balance',
  adminToMerchant: 'Transfer to Merchant by Admin',
  merchantToMerchant: 'Transfer from Merchant to Merchant',
  merchantToUser: 'Transfer to User from Merchant',
  activityNormal: 'Normal Activity',
  activityInvalid: 'Expired Activity',
  noActivity: 'No Activity',
  activityNoStarted: 'Not Started',
  discountPrice: 'Discount Price',
  PurchaseActivityDate: 'Purchase Count Activity Date',
  actualPrice: 'Actual Price',
  hasActivity: 'Package Activity',
  notActivity: 'Non-Activity',
  ActivitySetting: 'Activity Settings',
  PackageDate: 'Package Launch Date',
  buyLimitCountMsg: 'Please select or enter purchase limit count',
  goodTypeMsg: 'Please select subscription type',
  hasActivityMsg: 'Please select activity type',
  enterDateMsg: 'Please select a date',
  verifyNotPass: 'Verification failed',
  discountPriceYuanErrorMsg: 'Please enter actual price',
  priceYuanErrorMsg: 'Please enter discount price',
  menuNameErrorMsg: 'Please enter package name',
  lastTimeErrorMsg: 'Please enter membership validity period',
  sortErrorMsg: 'Please enter sorting order',
  shopName: 'Shop Name',
  shopNamePlaceholder: 'Enter Shop Name',
  merchantNamePlaceholder: 'Enter Merchant Name',
  scoreNotEmpty: 'Score cannot be empty',
  scorePlaceholder: 'Enter Score',
  monthScore: 'Monthly Rating',
  monthScoreNotEmpty: 'Monthly rating cannot be empty',
  isOpenClose: 'Is Open',
  updateSort: 'Update Sorting Order',
  openSuccess: 'Opened Successfully',
  closeSuccess: 'Closed Successfully',
  limitPurchaseTimes: 'Limit Purchase Times',
  iconPlaceholder: 'Enter Icon',
  imageUploadTip128:
    'Image upload limit: Image size must be 128x128, file size must be below 100KB.',
  shopTag: 'Shop Tag',
  shopTagPlaceholder: 'Enter Shop Tag',
  defaultIcon: 'Default Icon',
  filterDate: 'Filter Date',
  lastPeriod: 'Last Period',
  userID: 'User ID',
  merchantOrgchart: '{msg} Organizational Chart',
  merchant_id: 'Bank Merchant',
  shop_id: 'Shop',
  rankList: 'Ranking List',
  score: 'Number of Ratings',
  avg: 'Average Score',
  service_attitude: 'Service Attitude',
  recharge_time: 'Top-up Speed',
  threeMonth: 'Last Three Months',
  TOP: 'Top Three',
  LAST: 'Last Three',
  userScore: 'User Ratings',
  merchantPlaceholder: 'Please Select Bank Merchant',
  shopPlaceholder: 'Please Select Shop',
  tradeType: 'Transaction Type',
  platformAccount: 'Platform Account',
  gameAccount: 'Game Account',
  accountType: 'Account Type',
  actionType: 'actionType',
  addPopcoin: 'Add Popcoin',
  deductPopcoin: 'Deduct Popcoin',
  rechargeCompensationOrder: 'Recharge Compensation Order',
  rechargeFailure: 'Recharge Failure',
  rechargeAdjustAmount: 'Recharge Adjust Amount',
  withdrawalCompensationOrder: 'Withdrawal Compensation Order',
  withdrawalFailure: 'Withdrawal Failure',
  withdrawalAdjustAmount: 'Withdrawal Adjust Amount',
  disableWithdrawal: 'Disable Withdrawal',
  enableWithdrawal: 'Enable Withdrawal',
  permissionDenied: 'There is no authority in the current operation',
  transferToUserSuccess: 'Transfer Successful',
  disableGameAccount: 'Disable Game Account',
  enableGameAccount: 'Enable Game Account',
  disableWithdraw: 'Disable Withdrawal',
  enableWithdraw: 'Enable Withdrawal',
  gameAccountStatus: 'Game Account Status',
  withdrawStatus: 'Withdrawal Status',
  disableGameAccountMsg: "Are you sure you want to disable user '{msg}'",
  disableWithdrawMsg: "Are you sure you want to disable withdrawal for '{msg}'",
  confirmDisable: 'Confirm Disable',
  askList: 'Inquiry List',
  setTransfer: 'Specify Transfer',
  userIdRequest: 'Please enter userID',
  RECHARGE: 'top up',
  RECHARGE_BONUS: 'Advantage and gift gold',
  RECHARGE_AMOUNT_CHANGE: 'Recharge adjustment',
  RECHARGE_FAIL: 'Recharge refund',
  BUY_SUBSTANCE: 'Buy video',
  BUY_SVIP: 'Purchase member',
  GOLD_TRANSFER_GAME: '{0} transfer to game coins',
  WALLER_TRANSFER_GOLD: '{0} transfer to {1}',
  GAME_TRANSFER_GOLD: 'Game currency transfer to {0}',
  WITHDRAWAL: 'withdraw',
  WITHDRAWAL_BONUS_CHANGE: 'Gettlement Gold Adjustment',
  WITHDRAWAL_BONUS: 'Get off',
  BACKSTAGE_ADD_GOLD: 'Platform plus {0}',
  BACKSTAGE_DEDUCTION_GOLD: 'Platform deduction {0}',
  GAME_TRANSFER_WALLET: 'Game coin transfer to {0}',
  normal: 'Normal',
  banSuccess: 'Disable',
  unlockedSuccess: 'Successive ban',
  queryUserId: 'query UserId',
  nowWalletBalance: 'Current {0} balance',
  nowGameCoinBalance: 'Current game currency balance',
  recharge: 'top up',
  rechargeToolTips: 'Promotion = recharge+fiat currency purchase membership',
  withdrawMoney: 'withdraw',
  bet: 'bet',
  buyVideo: 'Buy video',
  loginIPHistoty: '登录IP历程',
  waitAudit: '待审核',
  auditLog: '审核纪录',
  selectWithdrawType: '选取领取状态',
  bankNoTimes: '银行卡号绑定次数',
  gameProfitLoss: '用户游戏币盈亏比例',
  deviceNoIsOk: '设备码是否正常',
  IPIsOk: 'IP地址是否正常',
  get: '领取',
  getTime: '领取时间',
  getUser: '领取人',
  backOrder: '退单',
  reject: '拒绝',
  allow: '通过',
  selectAuditAccount: '选择审核账号',
  processDoneTime: '处理完成时间',
  auditUser: '审核人',
  waitAuditDesc: '上次提现后的游戏币盈亏/转入游戏币金额-游戏币转啪啪币金额，若距上次提现超90日，则以90日为主',
  deviceDetail: '设备码详情',
  deviceNo: '设备码',
  WithdrawalerName: '提款人姓名',
  loginIP: '登录IP',
  loginIPRecent: '近一次登入IP',
  NOT_GET: '未领取',
  GET_DONE: '已领取',
  backNoDetail: '银行卡号详情',
  loginTime: '登录时间',
  loginIPDetail: '登录IP详情',
  auditStatus: '审核状态',
  auditRemark: '审核备注',
  NOT_WITHDRAWAL: '申请中',
  auditType: '审核方式',
  AUTO: '自动',
  ARTIFICIAL: '人工',
  SYSTEM: 'system',
  auditor: 'Choose reviewer',
  chooseOperatedOption: 'Choose the operation method',
  SET_BALANCE: 'Adjust the amount',
  FILL_ORDER: 'Make up',
  theCurrentCardholderName: "The current cardholder's name",
  modifyCardholderNameBankCard: 'Modify the card holder name of the bank card',
  modifyNameCardman: 'Modify the name of the cardman',
  changeTime: 'Change time',
  pleaseSelectBankcardStatus: 'Please select the bank card status',
  all: 'all',
  effect: 'efficient',
  userNickname: "User's Nickname",
  nameOfTheCardholderAfterModification: 'The name of the cardholder after modification',
  enterNameCardholderAfterModification:
    'Please enter the name of the cardholder after the modification',
  modifyTheCardholderOfOtherBankCardsSimultaneously:
    'Modify the cardholder of other bank cards simultaneously',
  deleteAllBankCards: 'Delete the old bank card and modify the name of the cardman',
  cardholderCantbeSame: "The card holder's name is the same and cannot be modified",
  noPerms: 'No permission query',
  userInfo: 'Basic information',
  membership: 'Membership',
  memberRemainingDays: 'Member remaining days',
  setSvip: 'Set super member',
  setSvipDays: 'Set up super membership days',
  pleaseSetSvipDays: 'Please enter the number of membership days',
  settingSuccess: 'Successfully set',
  PAYING: 'unpaid',
  FAILURE: 'fail',
  CANCELED: 'fail',
  NOT_PAY: 'To be paid',
  rechargeTimeLimit: 'Recharge time limit',
  guideChannel: 'Guide channel',
  realNameSystem: 'Real-name system',
  uploadCertificate: 'upload certificate',
  whetherToEnable: 'Whether to enable',
  pleaseUploadImage: 'Please show above',
  paymentIcon: 'Payment icon',
  uploadReturnOrder: 'Upload a return order',
  revenueAndExpenditure: 'Revenue and expenditure',
  paymentLimit: 'Payment time limit',
  pleaseEnterLimitTime: 'Please enter the limit time',
  pleaseSelectGuidanceChannels: 'Please select guidance channels',
  minute: 'minute',
  imageWidthHeightErrorFormat:
    'The size of uploading the picture is not in line, it can only be{0}x{1}!',
  imgFormatText: 'size：{0}*{1}\ncapacity：Below 5MB\nFormat file：png',
  uploadImg: 'Upload a picture',
  upPoint: 'Score',
  downPoint: 'Score',
  bonus: 'Rebate',
  withdrawalBonus: 'Rebate',
  rechargeBonus: 'Recharge',
  addPoint: 'Make up',
  RECHARGE_AMOUNT_CHANGE_ADD: 'Recharge',
  WITHDRAWAL_BONUS_CHANGE_ADD: 'Withdrawal',
  deduction: 'Deduction',
  RECHARGE_AMOUNT_CHANGE_DEDUCTION: 'Recharge deduction',
  WITHDRAWAL_BONUS_CHANGE_DEDUCTION: 'Withdrawal deduction',
  order: 'Order',
  WITHDRAWAL_FAIL_RETURN: 'Withdrawal failure',
  FRIENDS_CONSUMPTION_REBATE: 'Rebates on friends’ purchases',
  withdraw_WITHDRAWING: 'Processing',
  withdraw_FAIL: 'fail',
  withdraw_COMPLETE: 'success',
  withdraw_NOT_WITHDRAWAL: 'Application',
  withdrawAmountType: 'Make a cash amount',
  fixed: 'fixed',
  fixedMoneyPlaceholder: 'Please enter a fixed amount',
  amountRange: 'Scope',
  amountMinLimit: 'Minimum amount',
  amountMaxLimit: 'Maximum amount',
  withdrawAmountWay: 'Method for the amount',
  fixedMoneyAndOtherMoney: 'Fixed amount & other amount',
  enterAmount: 'Input amount',
  withdrawSwitch: 'Withdrawal switch',
  reminderTimeInterval: 'Single time spacing',
  withdrawing: 'Export',
  disbursed: 'Exist',
  pleaseEnter: 'please enter',
  timeRange: 'Spiries: 1--9999',
  estimatedArrivalTime: 'Expected time for accounting',
  normalWithdrawSwitch: 'Ordinary withdrawal switch',
  reserveWithdrawSwitch: 'Appointment with withdrawal switch',
  rebate: 'Rebate',
  withdrawalMethod: 'Withdrawal',
  pleaseSelectWithdrawalMethod: 'Please select the withdrawal method',
  selectPaymentMethod: 'Choose a payment method',
  paymentMethod: 'payment method',
  sdl_tunnel_id: 'SDL channel number',
  NORMAL: 'normal',
  gameId: 'Gaming ID',
  gameName: 'game name',
  gameImg: 'Gaming icon',
  addOne: 'Add to',
  gameLobby: 'Games Lobby',
  deleteAllBankCard: 'Delete all bank cards',
  date: 'date',
  paymentCount: 'paymentCount',
  paymentAmount: 'paymentAmount',
  withdrawCount: 'withdrawCount',
  withdrawAmount: 'withdrawAmount',
  transferOut: 'transfer out',
  transferIn: 'transfer',
  gameToMsg: '(game currency to {msg})',
  msgToGame: '({msg} transfer game currency)',
  remainAmount: 'remaining amount',
  betAmount: 'Bet amount',
  effectBetAmount: 'effective bet amount',
  agShare: 'Agent share',
  myProfit: 'My Profit',
  actProfit: 'actual profit',
  gameToken: 'game token',
  channel: 'channel',
  withdrawUserType: 'Withdrawal user type',
  agentWithdraw: 'Distribution withdrawal',
  userWithdraw: 'User Withdraw',
  sumPaymentCount: 'Total number of payment orders',
  sumPaymentAmount: 'Total payment order amount',
  sumWithdrawCount: 'Total number of withdrawal orders',
  sumWithdrawAmount: 'Total withdrawal order amount',
  exportWithdrawDetail: 'Export withdrawal details',
  exportPaymentDetail: 'Export payment details',
  sumTransferOut: 'Total transfer Out',
  sumTransferIn: 'Total transfer In',
  sumBetAmount: 'Total bet amount',
  sumEffectBetAmount: 'Total effective bet amount',
  sumMyProfit: 'Total my profit',
  sumActProfit: 'Total actual profit',
  everyDayDetail: 'Daily details',
  exportTransferOutDetail: 'Export transferOut details',
  exportTransferInDetail: 'Export transferIn details',
  wayReport: 'channel report',
  paymentReport: 'Payment Detail Report',
  withdrawReport: 'Withdrawal details report',
  gameReport: 'Game report',
  transferOutReport: 'TransferOut detailed report',
  transferInReport: 'TransferIn detailed report',
  NumberOfDays: 'Number of days',
  permanent: 'permanent',
  pleaseEnterDistributorAccount: 'Please enter the channel business account',
  pleaseEnterGoodsOrderNum: 'Please enter the product order number',
  withdrawCardNo: 'Withdrawal card number',
  ARRIVAL_TIME: 'Time to account',
  operationLog: 'Operating log',
  distributorWithdrawOperationLog: 'Channel merchant withdrawal operation log',
  distributorWithdrawSetting: 'Channel merchant withdrawal setting',
  withdrawalAmountRange: 'Withdrawal amount',
  withdrawalMoneyPlaceholder: 'Please enter the withdrawal amount',
  accountStatus: 'Account status',
  disabled: 'Disable',
  WITHDRAWAL_CHANGE: 'Adjustment of the current amount',
  returnGameToken: 'Back to the game tokens',
  returnGameTokenErrorMsg: 'Please enter the gaming tokens',
  memberPackageIsEnable: 'Member package is enabled, cannot be deleted',
  gameTokenBalance: 'Gaming token balance',
  addGameToken: 'Gaming tokens',
  deductGameToken: 'Token',
  gameTokenCount: 'Number of tokens',
  officialCertification: 'Official certification',
  batchDisabledAccountContent: 'Is it confirmed to disable {0} an account number?',
  batchEnabledAccountContent: 'Is it determined to lift the ban {0} an account number?',
  serviceTitlePlaceholder: 'Limit 20 words',
  disableAccountNumber: 'Disable account number',
  batchLiftingAccount: 'Batch lifting account',
  customerService: 'Customer service',
  CustomerServiceNotificationTitle: 'Customer service notification title',
  disableAccount: 'Disable account',
  liftingAccount: 'Lifting account',
  resetPassword: 'reset Password',
  setMobilePhoneNumber: 'Set mobile phone number',
  setAvatar: 'Set Avatar',
  setTheNickname: 'Set the nickname',
  userDetailMobile: 'Phone number',
  registrationTime: 'Registration time',
  age: 'age',
  gender: 'gender',
  area: 'area',
  numberOfReports: 'Number of reports',
  male: 'male',
  female: 'female',
  secrecy: 'Secret',
  disableUserMsg: "Whether to confirm the disable'{msg}'account",
  resetPasswordSuccess: 'Reset password success',
  nickNamePlaceholder: 'Please enter a nickname',
  nickNameMinString: 'The nickname length should not be less than 2 characters',
  nickNameMaxString: 'The length of the nickname cannot be greater than 8 characters',
  copy: 'copy',
  copySuccess: 'Replication',
  mustSelectOne: 'Please choose one',
  noLoginDay: 'Number of days',
  pleaseInputMobile: 'Please enter the login mobile phone number',
  mobileErrorMsg: 'Phone number format is incorrect',
  previewAvatar: 'Avatar preview',
  imgSameScaleTip: 'Please upload a file with a width height ratio of 1: 1',
  proxyDataOfWebsite: 'National Agent Website Data',
  numberOfVisitors: 'Website opening number',
  downloadTimes: 'Click to download the number',
  numberOfNewUsers: 'Promotion code download new number of new users',
  currentRatio: 'Currently divided into a proportion',
  subscribersCount: 'Number of charging users',
  gameRevenue: 'Game income',
  currencyType: 'Currency',
  sourcesOfFunds: 'Sources of funds',
  aiDataSetting: 'A I auxiliary generating data settings',
  aiScheduleSetting: 'A I auxiliary generation schedule settings',
  minValue: 'Minimum',
  maxValue: 'Maximum',
  switch: 'switch',
  closeWhenOverThreshold:
    'The original data surpasses this value and automatically skip the execution',
  to: 'to',
  thresholdPlaceholder: '1-999999',
  SUBSTANCE_COMMENT: 'Number of comments',
  SUBSTANCE_LIKE: 'Number of likes',
  SUBSTANCE_PLAY: 'Watch the number',
  SUBSTANCE_COLLECT: 'Collecting number',
  SUBSTANCE_DOWNLOAD: 'Number of downloads',
  SUBSTANCE_FORWARD: 'Forward number',
  everyDay: 'daily',
  H: 'hour',
  M: 'point',
  or: 'or',
  every: 'Every',
  everyTime: 'every time',
  count: 'Pen',
  repeatImplement: 'Execute',
  hourList: '0-23',
  minuteList: '0-59',
  minMaxDataError: 'The maximum range cannot be less than minimum value',
  overThresholdError:
    'The minimum and maximum value cannot be greater than the automatic closing of the field value',
  aiDataSettingTips1:
    '1. The number of correlations will be randomly generated in the two numerical spacing, and a certain value can be opened or closed by itself <br/> 2. The unit and the quantity<br/> 3. The minimum or maximum value cannot be greater than the automatic closing the field value',
  aiScheduleSettingTips1:
    'Single selection tick switch, two selection one <br/> drop-down menu <br/> time is 0-23, with 0-59',
  aiScheduleSettingTips2: 'Unit and quantity <br/> 1-30 pen must not be 0 or negative, empty level',
  aiScheduleSettingTips3:
    'When this switch is turned on, you can repeatedly execute all the data in the auxiliary generation settings and the videos that have been executed.',
  aiScheduleSettingWarning:
    'The unit and the number 1-30 cannot be 0 or negative number, empty level',
  alreadySend: 'Already sent',
  waitSend: 'Not sent yet',
  cancelSend: 'Cancel sending',
  imgSize363x150: 'Size: 363*150 Capacity: Formats below 5m below: PNG',
  imgSize363x150HTML: 'Size: 363*150 <br> Capacity: 5m below <br> Format file: PNG',
  addSuccess: 'Operation successful',
  sendMessage: 'Send message',
  userMessage: 'User message',
  enterMessageTitle: 'Enter message title',
  selectMessageStatus: 'Select message status',
  pushTime: 'Push time',
  messageTitle: 'Message title',
  sender: 'Sender',
  targetUser: 'Target user',
  messageStatus: 'Message status',
  readRate: 'Read rate',
  checkIt: 'Check it',
  content: 'Content',
  imgIcon: 'Image icon',
  graphicJump: 'Graphic jump',
  selectGraphicJump: 'Select graphic jump',
  messageType: 'Message type',
  systemMessage: 'System message',
  allUsers: 'All users',
  superVipUsers: 'Super VIP users',
  partialUsers: 'Partial users',
  enterPartialUserID: 'Enter partial user ID',
  selectedUserCount: 'Current selected user count: ',
  batchUpload: 'Batch upload',
  downloadExample: 'Download example',
  push: 'Push',
  messageCenterNotification: 'Message center notification',
  pushImmediately: 'Push immediately',
  schedulePush: 'Schedule push',
  scheduleTime: 'Schedule time',
  selectDateAndTime: 'Select date and time',
  noRedirect: 'No redirect',
  downloadList: 'Download list',
  immediately: 'Immediately',
  scheduled: 'Scheduled',
  pleaseInputContent: 'Please input content',
  pushWay: 'Push way',
  pleaseInputLink: 'Please input link',
  pleaseInputPushWay: 'Select push way',
  onlyUploadXLSX: 'Only upload .xlsx files',
  pleaseInputMsgType: 'Select message type',
  pleaseInputReadStatus: 'Select read status',
  msgType: 'Message type',
  msgContent: 'Message content',
  readAndUnread: 'Read/Unread',
  previewMsg: 'Preview message',
  more: 'More',
  KEFU: 'Customer service',
  isReaded: 'Read',
  unread: 'Unread',
  messageCenterNotificationAndPush: 'Push, message center notification',
  imgLink: 'Image link',
  clickDownload: 'Click download list',
  other: 'other',
  noNowTime: 'Do not set the current time',
  sendList: 'Send a list',
  parameterName: 'parameter name',
  parameterValue: 'Parameter',
  logoutDays: 'Number of days without login',
  logoutDaysCondition:
    'This parameter controls the withdrawal of the abnormal conditions for a long time, with a maximum value of 3650 days. Entering 0 is equal to the judgment shutdown.',
  suspectLists: 'Suspect list of withdrawal names',
  suspectListsCondition:
    'This parameter controls the withdrawal of the abnormal conditions of the name of the withdrawal of the withdrawal of the withdrawal',
  dayUnit: 'sky',
  pleaseEnterLogoutDays: 'Please enter the number of days without login for a long time',
  payName: 'Payer name',
  phoneNumber: 'phone number',
  loginAreaIsNormal: 'Is it normal to log in to the area?',
  logoutForLongTime: 'Login for a long time',
  paymentOrderIsNormal: 'Is the payment order normal?',
  withdrawerDetail: 'Details of the withdrawal of the withdrawal',
  loginAreaDetail: 'Log in to the area details',
  sinceLastLoginTime: 'From the last time you log in',
  paymentOrderDetail: 'Pay order details',
  lastTenApplication: 'Total applications nearly 10 times',
  lastTenDeposits: 'The total number of accounts arrived nearly 10 times',
  lastTenArrivals: 'Cast 10 times to account rate',
  enableThisVersion: 'Enable this version',
  downloadWebsite: 'Download URL',
  abandoned: 'abandoned',
  paymentOrderIsNormalTips:
    'Abnormal definition: Those who have recharged the accounts nearly 10 times or have different names of the payer',
  selectMessageType: 'Select message type',
  chooseASender: 'Choose a sender',
  manual: 'Manually',
  platformAnnouncement: 'Platform announcement',
  nationalAgent: 'National agent',
  gamingAgent: 'Gaming agent',
  thread: 'Withdrawal message',
  rechargeMessage: 'Recharge message',
  gamingAgentIdentity: 'Gaming agent identity',
  proxyIdentity: 'Proxy identity',
  superiorAgentID: 'Superior agent ID',
  agentBindingTime: 'Agent binding time',
  totalNumberOfLowerAgents: 'Total number of lower agents',
  directSubUserNumber: 'Number of subordinates',
  directUserNumber: 'Number of users',
  signedContracts: 'Signed quantity',
  notSetContracts: 'Number of contracts',
  awaitingContracts: 'Number of contracts',
  levelOne: 'First -level agent',
  levelTwo: 'Secondary  -level agent',
  levelThree: 'Third -level agent',
  levelFour: 'Four -level agent',
  levelFive: 'Five -level agent',
  directUser: 'Direct user',
  clickPercent: 'Click rate',
  installPercent: 'Installation rate',
  downloadAddUser: 'Download the number of new users',
  officalData: 'Official promotion page data',
  installTechData: 'Install teaching page data',
  inviteSumData: 'Promotion total data',
  selectDate: 'Select time',
  frequency: 'Second-rate',
  domainNamePosition: 'Domain name position',
  domainNameAddress: 'Domain name address',
  pleaseEnterDomainName: 'Please enter the domain name',
  newAgent: 'New agent',
  promotionLink1: 'Promotion link 1',
  promotionLink2: 'Promotion link 2',
  pleaseEnterDomainName2: 'Please enter the domain name of {0}',
  abandonedHistorical: 'Abandoned historical list',
  appDomainBame: 'APP domain name',
  abandon: 'Abandon',
  maxTenItems: 'Only create only 10 projects at most',
  maxHundredItems: 'Only create only 100 projects at most',
  noAbandonInEnabled: 'Do not abandon the network in enabling the state',
  domainReplaceMessage:
    'Enter the network domain to overlap in the abandoned history list, please enter other network domains',
  isAbandonDomain: '{0} is abandon the domain',
  resetSuccess: 'Reset',
  abandonDomain: 'Abandon',
  abandonTime: 'Abandonment time',
  rechargeToGetSvip: 'Recharge and send SVIP',
  singleRechargeFull: 'Single recharge',
  sendMember: 'Send member',
  isNoLessThan: 'greater or equal to',
  isLessThan: 'Less than',
  rechargeToGetSvipTips:
    'The number must be 0-999999. The former should not be overlapped, and the number of recharge fields should not be greater than the latter. Negative numbers, empty levels, and symbols cannot be entered.',
  membershipDetails: 'Member identity details',
  querySource: 'Query source',
  source: 'source',
  obtainTime: 'Time',
  domainName: 'domain name',
  firstRechargeBonus: 'First recharge discount',
  firstBonusRatio: 'The proportion of dividends',
  firstBonusRatioPlaceholder: 'Please enter the premiere dividend ratio',
  firstBonusContent: 'The content of the first charging case',
  firstBonusContentPlaceholder: 'Please enter the content of the first charging text',
  taskRewardConfig: 'Mission reward configuration',
  openMission: 'Mission opening',
  closeMission: 'Mission shutdown',
  SVIP: 'Super member',
  GAME_COIN: 'Gaming bonus',
  VIDEO_CARD: 'Movie voucher',
  giftDays: 'Gift days',
  giftBonus: 'Gif',
  giftCoupons: 'Number of gift vouchers',
  saveConfig: 'Save configuration',
  webAndMailConfig: 'Task network & email configuration',
  addMission: 'New task',
  mission: 'Task',
  missionTitle: 'Task title',
  missionSubtitle: 'Sideline',
  domain: 'Mesh domain',
  buttonText: 'Button text',
  sendMailMessage: 'Send mail message',
  clickLinkMission: 'Click to link task',
  sendMailMission: 'Mailbox delivery task',
  saveOfficialLinkMission: 'Official connection saving task',
  nWordLimit: 'Only {0} individual yuan',
  pleaseEnterValidUrl: 'Please enter the valid website',
  fourWorldLimit: 'Only 4 words',
  maximumOfFiveMissions: 'A maximum of 5 tasks',
  atLeastOpenOneAward: 'Please start at least one reward',
  pleaseSelectMissionType: 'Please select the task type',
  questionnairePlace: 'Questionnaire',
  questionnaireTitle: 'Questionnaire title',
  questionnaireType: 'Questionnaire',
  questionnaireContent: 'Questionnaire',
  statistics: 'statistics',
  closingTime: 'Closing time',
  questionnaireEdit: 'Questionnaire Edit',
  questionnaireStatistics: 'Questionnaire statistics',
  whetherToDelete: 'Whether to delete?',
  otherReasons: 'other reasons',
  onlyOneSelect: 'Alone',
  multipleSelect: 'Check',
  questionnairePositionType1: 'Recharge-cancel orders',
  questionnairePositionType2: 'Cosmetics-Cancel Order',
  details: 'Please',
  startingTime: 'Starting time',
  participantNum: 'Participate in the number of statistics',
  questionNature: 'Question nature',
  select: 'choose',
  numberOfChoices: 'Number of choices',
  questionnaireTitlePlaceholder: 'Please enter the questionnaire name, limit 10 words',
  questionNatureToolTips:
    'Enter yourself: You can only choose one, select the second prompt: You can only select 1 input by yourself. When you enter it yourself, it is not the last one. After saving, it will be automatically adjusted to the last one.',
  cancelTypePlaceholder: 'Limited to 15 words',
  questionnaireErrorMsg1: 'The questionnaire in enable cannot be deleted',
  questionnaireErrorMsg2: 'Please enter the question content',
  time: 'time',
  percentage: 'Proportion(%)',
  firstRechargeFeedbackTips:
    'The numbers must be 1-999999. The former must not be overlapped, and the number of rechargeable fields should not be greater than the latter. Negative numbers, empty levels, and symbols cannot be entered.',
  confirmSave: 'Confirm settings',
  firstChargerSettings: 'First Charger Settings',
  RechargeIncreaseMoneyFeedbackSettings: 'Recharge increase money feedback settings',
  giftMoney: 'Gif',
  advantageIncreasedGoldDiscount: 'Advantage increased gold discount',
  registrationSource: 'Sources',
  promotionCode: 'Promotion code',
  equipmentInfo: 'Equipment information',
  ip: 'IP address',
  deviceNumber: 'Equipment number',
  assetInfo: 'Asset information',
  ticketsRemainder: 'The remaining number of viewing vouchers',
  integralRemainder: 'Smoral',
  accountInfo: 'Account information',
  accumulatedVipPurchases: 'Cumulative purchase of VIP',
  accumulatedRecharge: 'Cumulative recharge {0}',
  accumulatedGameCoinExchange: 'Cumulative game currency exchange',
  accumulatedWithdraw: 'Cumulative {0} withdrawal',
  accumulatedGameCoinConsumption: 'Cumulative game lottery consumption',
  accumulatedUseOfTickets: 'Cumulative movie viewing vouchers',
  deviceModel: 'Device model',
  deviceSystem: 'Equipment system',
  agentInfo: 'Proxy information',
  nationalAgentLevel: 'National agency level',
  directSuperiorID: 'Game superior agent ID',
  agentBindingTime: 'Game agent binding time',
  gameAgentIdentity: 'Gaming agent identity',
  videoRecommendationTendencySetting: 'Video recommendation tendency setting',
  popularDescription: '*The smaller the value, the more Volkswagen browsing the video.',
  specializationDescription:
    '*The higher the value, the more you recommend the label videos that are more focused on the user interaction.',
  popular: 'Popular',
  specialization: 'Exclusive',
  videoRecommendationWeightSetting: 'Video recommendation weight setting',
  weightSettingDescription: '*Please input the number between 1.0 ~ 2.0, and cannot be empty.',
  userBehavior: 'user behavior',
  FAVORITE: 'User preference',
  INTERACT: 'User interaction',
  weightContent: 'Weight content',
  VIDEO_WATCH_TIME: 'Video watch time',
  CLICK_VIDEO_TAG: 'Click the video label',
  ADD_TAG: 'Add custom tag',
  VIDEO_LIKE: 'Video Praise',
  VIDEO_COLLECT: 'Video collection',
  VIDEO_DISCUSS: 'Video comment',
  DANMU_MESSAGE: 'Barrage message',
  weightedProportion: 'Weighted proportion',
  weightPlaceholderText: '1.0 ~ 2.0',
  confirmSetting: 'Confirm settings',
  passwordRule:
    'Must contain large and small letters, numbers, special symbols, 8-16 characters in length',
  promotionLinkTemplate: 'Promotion link template',
  landingPageTemplate: 'Field page template',
  openOrNot: 'Whether it is open',
  promotionLinkLandingPage: 'Promote the connected landing page',
  promotionalLink: 'Promotion link',
  getLink: 'Obtain connection',
  getQRCode: 'Get a QR code',
  noData: 'No data',
  CLICK_VIDEO: 'Click the video',
  parameter: 'parameter',
  gameAgentSetting: 'Game proxy settings',
  setAgentIdentity: 'Set an agent identity',
  setAgentContract: 'Set proxy contract',
  settingMethodTips: 'Setting method: from the minimum value to the maximum value',
  serialNumberOfRule: 'Rules serial number',
  dividedRatio: 'Division ratio (%)',
  netWinLossOfMonth: 'Winning and winning for the month',
  netWinLossMaximumTips: 'Unlimited large = 999999999',
  minimumRequirementsForActiveUsers: 'Active user minimum requirements',
  activeUsersDefinition:
    'Active user definition: recharge of the month> = 100 or valid betting> = 500',
  maximum10: 'Up to 10',
  noSmallerThan: 'Unparalleled rules {index} small',
  overlappingWith: 'Reconstruction of the rules {index}',
  noEmpty: 'no empty',
  agentTreeMigration: 'Agent tree migration',
  agentIdentity: 'Proxy identity',
  superiorAgentIDBeforeModify: 'Modify the former superior agent ID',
  superiorAgentIDAfterModify: 'After modification, the superior agent ID',
  pleaseEnterSuperiorID: 'Please enter the superior ID',
  agentTreeMigrationTips:
    'Reminder: After the superior is replaced, all subordinates need to be signed again.',
  gameAgentContractDetail: 'Game agent contract details',
  contractContent: 'Contract content',
  sendContractTime: 'Send a contract time',
  contractConfirmTime: 'Contract confirmation time',
  numberOfPeople: 'Number of people',
  numberOfRule: 'Rules {index}',
  winOrLose: 'Win -win',
  numberOfActivePeople: 'Active number',
  divided: 'Divide',
  applicantID: 'Applicant ID',
  APPLICATION_TIME: 'application time',
  REVIEW_TIME: 'Review time',
  reviewResult: 'Audit results',
  hasSuperior: 'Is there a superior',
  applicantNickname: 'Applicant nickname',
  applicationPassed: 'application passed',
  beRejectedTips: 'The user has applied for being rejected, please be sure if you want to pass',
  addGameCommission: 'Add game commission',
  deductGameCommission: 'Deduction game commission',
  getGameAgentPromotionLink: 'Get the game agent promotion chain',
  gameCommissionBalance: 'Game commission balance',
  gameCommission: 'Gaming commission',
  gameCommissionAccount: 'Game commission account',
  nowGameommissionBalance: 'Current game commission balance',
  auditRecords: 'Review record',
  chooseMonth: 'Please select month',
  searchAgentID: 'Query proxy ID',
  agentID: 'Proxy ID',
  agentNickname: 'Acting nickname',
  Month: 'month',
  agentNetProfit: 'Agent rushing to win or losses',
  rebateRatio: 'Return ratio (%)',
  teamCommission: 'Team commission',
  personalCommission: 'Personal commission',
  lowerLevelTotalCommission: 'Subordinates',
  modifyPersonalCommission: 'Adjust personal commission',
  beforeModify: 'Before adjustment',
  afterModify: 'After adjustment',
  remarkLimitHundredWords: 'Remarks for 100 words',
  personalCommissionDetail: 'Personal commission details',
  modifyTime: 'Adjustment time',
  operatedAccount: 'Operating account',
  onlyShowTheLastTenRecords: 'Only show nearly 10 records',
  COMMISSION_TRANSFER_OTHERS: 'Commission turn {0}',
  PLATFORM_TRANSFER_IN: 'Platform transfer',
  SUPERIOR_TRANSFER_IN: 'Superior transfer',
  gameCoin: 'Game currency',
  PAY_TO_SUB_AGENT: 'Payment subordinates',
  routePathValidateMessage: 'The beginning of the path should be cy. ',
};
