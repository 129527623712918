export default {
  // ---------route------------------------
  "/wel": "wel",
  "/wel/index": "wel index",
  "/info": "info",
  "/info/index": "个人信息",
  "/content/sisters/among": "排行信息编辑",
  "/content/recommend/recommendRedact": "推荐信息编辑",
  "/content/management/compile": "内容详情管理",
  "/user/details/basicsInfo": "用户基础信息",
  "/user/details/index": "user details",
  "/distributor-statistics/package-upload": "渠道数据统计",
  "/distributor-statistics/package-upload/details": "代理统计详情",
  "/distributor-manage/distributorlist/distributorDetails/index": "代理详情",
  "/distributor-manage/distributorlist/distributorUserList/index":
    "用户列表信息",
  "/distributor-manage/distributorlist/distributorUserList/distributorUserInfo/index":
    "用户信息",
  "/marketing/adlog": "广告日志",
  "/marketing/adlog/details": "广告详情",
  // ......from  api.....
  "/admin": "admin manage",
  "/admin/user/index": "user manage",
  "/admin/menu/index": "menu manage",
  "/admin/role/index": "role manage",
  "/admin/platformManage/index": "platform manage",
  "/admin/securityCenter/index": "security center",
  "/user": "user",
  "/user/userList/index": "user list",
  "/video-manage": "video-manage",
  "/video-manage/violationCheck/index": "violation check",
  "/video-manage/audit-record/audit-record": "audit record",
  "/video-manage/audit-record/my-audit-record": "my audit record",
  "/video-manage/strategy/allocation": "strategy allocation",
  "/video-manage/videoUpload/index": "supply video upload",
  "/video-manage/supplyVideoList/index": "supply video List",
  "/video-manage/consumerVideoList/index": "consumerVideoList",
  "/video-manage/triateralVideoList/index": "triateralVideoList",
  "/video-manage/waterMarkList/index": "waterMarkList",
  "/video-manage/video-processing-progress/index": "video-processing-progress",
  "/video-manage/videoList/index": "videoList",
  "/setting": "setting",
  "/admin/videoUploadSet/index": "videoRelatedSettings",
  "/admin/dict/index": "dict manage",
  "/adminId": "adminId",
  "/admin/major-key/index": "major-key",
  "/admin/data-monit/index": "data-monit",
  "/admin/cache-monit/index": "cache-monit",
  "/content": "content",
  "/content/management/index": "video-manage",
  "/content/discuss/claims": "discuss claims",
  "/content/discuss/record": "discuss record",
  "/content/recommend/manage": "recommend manage",
  "/content/sisters/brothers": "sisters brothers",
  "/content/classify/index": "classify manage",
  "/content/sensitive/index": "sensitive",
  "/content/navigation/navitber": "navigation manage",
  "/content/triateralplatform/index": "triateralplatform",
  "/report-and-feedback": "report-and-feedback",
  "/report-and-feedback/video/index": "video report",
  "/report-and-feedback/comment-on/index": "comment report",
  "/report-and-feedback/bullet-chat/index": "bullet-chat",
  "/report-and-feedback/user/index": "user report",
  "/report-and-feedback/violation/index": "violation reson",
  "/report-and-feedback/advertising-complaint/index": "advertising-complaint",
  "/marketing": "marketing center",
  "/marketing/planning/index": "planning",
  "/marketing/game/index": "game",
  "/marketing/advertise/index": "advertise",
  "/marketing/topsearch/index": "topsearch",
  "/marketing/message-center/index": "message-center",
  "/marketing/topicstore/index": "topicstore",
  "/marketing/adlog/index": "adlog",
  "/Appinventory": "appinventory",
  "/Appinventory/mymanage/mine": "mymanage",
  "/admin/version/index": "version",
  "/report-and-feedback/help/index": "help report",
  "/admin/suggest-feed/index": "suggest-feed",
  "/Appinventory/language/index": "language",
  "/Appinventory/installPackgeUpload/index": "installPackgeUpload",
  "/business-manage": "business-manage",
  "/business-manage/coinMenu/index": "coinMenu",
  "/business-manage/memberMenu/index": "member Menu",
  "/business-manage/memberList/index": "memberList",
  "/business-manage/orderGoodsList/index": "orderGoodsList",
  "/business-manage/memberBenefits/index": "memberBenefits",
  "/distributor-manage": "distributor-manage",
  "/distributor-manage/distributorlist/index": "distributorlist",
  "/distributor-manage/distributor-scheme/index": "distributor-scheme",
  "/distributor-statistics": "distributor-statistics",
  "/distributor-statistics/package-upload/index": "statistics package",
  "/withdraw": "withdraw",
  "/withdraw/distributor-withdraw/index": "distributor-withdraw",
  "/withdraw/fission-withdraw/index": "fission-withdraw",
  "/withdraw/proxy-withdraw/index": "proxy-withdraw",
  "/admin/SMsMgr/index": "SMS-Mgr",
  "/admin/SMsMgr/smsTemplate": "SMS-Template",
  "/content/referralPool/index": "Referral Pool",
  "/business-manage/channelRevenueDetail/index": "channelRevenueDetail",
  "/burialevent": "burialevent",
  "/burialevent/visualization/index": "visualization",
  "/burialevent/attributestore/index": "attributestore",
  "/video-manage/dismissReasons/index": "dismissReasons",
  "/video-manage/dissentingPool/index": "dissentingPool",

  // -----------------common start-------------------
  search: "search",
  reset: "reset",
  normal: "normal",
  disable: "disable",
  check: "check",
  edit: "edit",
  add: "add",
  change_password: "changePassword",
  enable: "enable",
  serial_number: "no",
  start_time: "start time",
  end_time: "end time",
  updated_by: "updated by",
  update_time: "update time",
  operate: "operate",
  delete: "delete",
  determine: "determine",
  cancel: "cancel",
  successfully_deleted: "successfully deleted",
  prompt: "prompt",
  confirm_content:
    "This operation will permanently delete the data. Do you want to continue?",
  confirm_forbidden:
    "This operation will be disabled. Do you want to continue?",
  confirm_enable: "This operation will be enabled. Do you want to continue?",
  confirm_disable:
    "Are you sure to disable this level account? After deletion, the revenue generated by customers under this agent will no longer be counted?",
  please_enter: "please enter",
  please_select: "please select",
  successfully_added: "successfully added",
  successfully_edited: "successfully edited",
  sort: "sort",
  status: "status",
  unit_day: "(unit day)",
  currency: "currency",
  phone: "phone",
  order_num: "order num",
  consume_type: "consume type",
  pay_type: "pay type",
  pay_way: "pay way",
  goods_name: "goods name",
  pay_time: "pay time",
  role: "role",
  no_selected_items: "No selected items",
  password: "password",
  confirm_password: "confirm password",
  platform: "platform",
  create_time: "create time",
  numbers_and_english: "numbers and english",
  user_name: "user name",
  existed: "existed",
  the_length_should_be: "the length should be",
  within_bits: "within_bits",
  contact_info: "contact info",
  correct: "correct",
  telephone: "telephone",
  batch: "batch",
  batch_delete: "batch delete",
  next_step: "next step",
  export: "export",
  add_time: "add time",
  verification_failed: "Verification failed",
  settlement_method: "Settlement method",
  index_title: "management system V 4.0.6",
  exit_full_screen: "exit_full_screen",
  full_screen: "full_screen",
  home_page: "home_page",
  personal_information: "personal_information",
  exit_system: "exit_system",
  platform_switch_successful: "platform_switch_successful",
  no_menu_found: "no menu found",
  close_other: "close other",
  close_all: "close all",
  more: "more",
  examine: "examine",
  handle: "handle",
  schedule: "Schedule",
  withdrawal_method: "Withdrawal method",
  withdrawal_amount: "Withdrawal amount",
  application_time: "Application time",
  reason: "Reason",
  pass: "Pass",
  by_success: "By success",
  view_credentials: "View credentials",
  proxy_account: "Proxy account",
  agent_name: "Agent Name",
  collection_account: "Collection account",
  voucher: "Voucher",

  // ---------route------------------------
  "/agent-manage/order-list/index": "agentWithdrawOrder",

  // ---------权限管理------------------------
  // 用户管理
  select_all: "Select all",
  please_enter_the_password_again: "Please enter the password again",
  the_two_passwords_not_match: "Two passwords do not match",
  real_name: "Real name",

  // ---------用户管理------------------------

  // ---------视频管理------------------------
  // 供应商视频上传
  upload_failed: "Upload failed",
  draft: "Draft",

  // ---------系统管理------------------------

  // ---------内容管理------------------------

  // ---------举报与反馈------------------------

  // ---------营销中心------------------------

  // ---------APP管理------------------------
  // 我的管理
  picture_preview: "Picture Preview",
  feature_name: "Feature name",
  affiliation_module: "Affiliation module",
  icon: "Icon",
  routing_path: "Routing path",
  please_upload_pictures: "Please upload pictures",

  // ---------商业化管理------------------------
  // 金币套餐
  amount: "Amount",
  number_purchases: "Number of purchases",
  obtaining_gold_coins: "Obtaining gold coins",
  single_account_purchase_restrictions: "Single account purchase restrictions",

  // 会员套餐
  subscription_type: "Subscription type",
  menu_name: "Menu name",
  fact_price: "Fact price",
  original_price: "Original price",
  member_validity_period: "Member validity period",
  member_validity_period_day: "Member validity period (days)",
  rules_menu_name: "Please enter 2-6 characters",

  // 会员列表
  nick_name: "Nick name",
  membership_type: "Membership type",
  pay_time: "Pay time",
  residual_interest: "Residual interest",
  expire_time: "Expire time",

  // 商品订单列表
  total_income: "Total income",
  month_income: "Month income",
  day_income: "Day income",
  refund_amount: "Refund amount",

  // 会员权益
  consumer_rights: "Consumer rights",
  consumer_rights_tip:
    "Consumer rights can be set to purchase gold coins or pay for video",
  recharge_coins: "Recharge coins",
  discounts: "Discounts",
  paid_video: "Paid video",
  offline_download_Benefits: "Offline download benefits",
  offline_download_Benefits_tip: "Set the number of video viewing",
  moreonthly_offline_downloads: "Monthly offline downloads",
  interactive_rights: "Interactive rights",
  interactive_rights_tip:
    "Set whether to be able to comment, barrage and collect",
  comment: "Comment",
  comment_tip: "SVIP's members will be able to comment on videos",
  barrage: "Barrage",
  barrage_tip: "SVIP's members will be able to send barrage to videos",
  collect: "Collect",
  collect_tip: "SVIP's members will be able to collect videos",
  save: "Save",

  // ---------渠道商管理 ------------------------
  // 渠道商列表-
  phone: "phone",
  full_name: "full name",
  channel_merchant_account: "channel merchant account",
  channel_merchant_name: "channel merchant name",
  channel_merchant_list: "channel merchant list",
  project_management: "projectManagement",
  set_password: "Set 6-digit password",
  set_scheme_permissions: "Set scheme permissions",
  programme: "programme",
  settlement: "settlement",
  level_commission: "level commission",
  illustrate: "illustrate",
  illustrate_content:
    "The 1-5 level commission will be used for the commission sharing of C-end users under the agent's name. For example, when the total of 1-5 levels is 40%, the remaining 60% will be distributed to the platform, channel, and agent according to the selected scheme.",
  correct: "correct",
  password_length:
    "Password length should be at least 6 digits and at most 20 digits",
  password_format: "Passwords can only contain letters and numbers",
  commission_rate: "commission rate",
  commission_type: "Commission ratio must be a positive integer",
  commission_total:
    "The total commission ratio cannot be greater than or equal to 100",
  add_commission: "Add up to 5 levels of commission",
  select_programme: "Please select at least one solution",
  modify_account: "Modify account",
  verify_password: "The two passwords do not match",

  // -渠道商管理 渠道商列表 渠道商项目
  channel_Details: "Channel details",
  channel_revenue: "Channel revenue",
  agency_income: "Agency income",
  project_name: "project name",
  system: "system",
  version: "version",
  total_commission: "Total contribution commission",
  withdrawn_commission: "Withdrawn commission",
  withdrawable_commission: "Withdrawable commission",
  agent_details: "Agent details",
  settlement_plan: "Settlement plan",

  // -渠道商方案管理-
  cps_programme: "cps programme",
  cpa_programme: "cpa programme",
  platform_division: "Platform division",
  channel_merchant_sharing: "Channel merchant sharing",
  agent_sharing: "Agent sharing",
  scheme_name: "Scheme name",
  set_percentage_value: "Set percentage value",
  rule: "Rule",
  positive_integer: "Only positive integers can be entered",
  input_total: "The total input value cannot exceed",
  three_input_total: "The sum of the three inputs must be equal to",

  // ---------渠道数据统计 -----------------------
  // -安装包统计-
  Channel_Merchant_project: "Channel merchant project",
  wrap_count: "Installation package statistics",
  acquisition_time: "Acquisition Time",
  invite_number: "Invite number",
  agency_project: "Agency project",
  agent_statistics_details: "Agent Statistics Details",
  // ---------提现管理 ----------------------
  // -渠道提现列表-
  order_number: "Order number",
  channel_withdrawal_list: "Channel withdrawal list",
  fail_to_succeed: "Fail to succeed",
  processing_results: "Processing results",
  success: "Success",
  fail: "Fail",
  failure_reason: "Failure reason",
  current_withdrawal: "Current settlement total withdrawal amount",
  Channel_settlement: "Channel Merchant Settlement Method",
  reason_for_failure: "Reason for failure",
  noPass: "Fail to pass",

  // -裂变提现列表-
  user_nickname: "User nickname",
  user_account: "User account",
  audit_time: "Audit time",
  reject: "Reject",
  reject_success: "Reject success",
  withdrawal_success: "Withdrawal success",
  withdrawal_failed: "Withdrawal failed",
  fission_withdrawal_list: "Fission withdrawal list",

  // -代理提现列表-
  withdrawal_path: "Withdrawal path",
  proxy_withdrawal_list: "Proxy withdrawal list",
  query: "Search",
};
