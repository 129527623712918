<template>
  <div class="login-container">
    <div class="login-weaper  animated bounceInDown">
      <div class="login-left">
        <p class="title">{{website.infoTitle}}</p>
      </div>
      <div class="login-border">
        <div class="login-main">
          <userLogin v-if="activeName==='user'"></userLogin>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import '@/styles/login.scss'
  import userLogin from "./userlogin";
  import {mapGetters} from "vuex";

  export default {
    name: "login",
    components: {
      userLogin
    },
    data() {
      return {
        activeName: "user"
      };
    },
    computed: {
      ...mapGetters(["website"])
    },
  };
</script>

