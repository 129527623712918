export default {
  // ---------route------------------------
  "/wel": "首頁",
  "/wel/index": "首頁",
  "/info": "信息",
  "/info/index": "个人信息",
  "/content/sisters/among": "排行信息编辑",
  "/content/recommend/recommendRedact": "推荐信息编辑",
  "/content/management/compile": "内容详情管理",
  "/user/details/basicsInfo": "用户基础信息",
  "/user/details/index": "用户详情",
  "/distributor-statistics/package-upload": "渠道数据统计",
  "/distributor-statistics/package-upload/details": "代理统计详情",
  "/distributor-manage/distributorlist/distributorDetails/index": "代理详情",
  "/distributor-manage/distributorlist/distributorUserList/index":
    "用户列表信息",
  "/distributor-manage/distributorlist/distributorUserList/distributorUserInfo/index":
    "用户信息",
  "/marketing/adlog": "广告日志",
  "/marketing/adlog/details": "广告详情",
  // ......from  api.....
  "/admin": "權限管理",
  "/admin/user/index": "用戶管理",
  "/admin/menu/index": "菜單管理",
  "/admin/role/index": "角色管理",
  "/admin/platformManage/index": "平臺管理",
  "/admin/securityCenter/index": "安全中心",
  "/user": "用戶管理",
  "/user/userList/index": "用戶列表",
  "/video-manage": "審核管理",
  "/video-manage/violationCheck/index": "違規審核",
  "/video-manage/audit-record/audit-record": "審核記錄",
  "/video-manage/audit-record/my-audit-record": "我的審核記錄",
  "/video-manage/strategy/allocation": "審核策略管理",
  "/video-manage/videoUpload/index": "供應商視頻上傳",
  "/video-manage/supplyVideoList/index": "供應商視頻上傳列表",
  "/video-manage/consumerVideoList/index": "C端視頻列表",
  "/video-manage/triateralVideoList/index": "三方視頻列表",
  "/video-manage/waterMarkList/index": "水印处理",
  "/video-manage/video-processing-progress/index": "視頻處理進度",
  "/video-manage/videoList/index": "視頻上传列表",
  "/setting": "系統管理",
  "/admin/videoUploadSet/index": "視頻相關設置",
  "/admin/dict/index": "字典管理",
  "/adminId": "分布式ID管理",
  "/admin/major-key/index": "分布式主鍵管理",
  "/admin/data-monit/index": "數據庫監控",
  "/admin/cache-monit/index": "緩存監控",
  "/content": "內容管理",
  "/content/management/index": "視頻管理",
  "/content/discuss/claims": "評論審核",
  "/content/discuss/record": "彈幕審核",
  "/content/recommend/manage": "推薦管理",
  "/content/sisters/brothers": "排行管理",
  "/content/classify/index": "標簽管理",
  "/content/sensitive/index": "敏感詞庫",
  "/content/navigation/navitber": "導航頁管理",
  "/content/triateralplatform/index": "三方平臺管理",
  "/report-and-feedback": "舉報與反饋",
  "/report-and-feedback/video/index": "視頻舉報",
  "/report-and-feedback/comment-on/index": "評論舉報",
  "/report-and-feedback/bullet-chat/index": "彈幕舉報",
  "/report-and-feedback/user/index": "用戶舉報",
  "/report-and-feedback/violation/index": "違規原因",
  "/report-and-feedback/advertising-complaint/index": "廣告投訴",
  "/marketing": "營銷中心",
  "/marketing/planning/index": "活動中心",
  "/marketing/game/index": "遊戲中心",
  "/marketing/advertise/index": "廣告管理",
  "/marketing/topsearch/index": "熱搜管理",
  "/marketing/message-center/index": "消息中心",
  "/marketing/topicstore/index": "話題庫",
  "/marketing/adlog/index": "廣告日誌",
  "/Appinventory": "App管理",
  "/Appinventory/mymanage/mine": "我的管理",
  "/admin/version/index": "版本管理",
  "/report-and-feedback/help/index": "幫助問題管理",
  "/admin/suggest-feed/index": "反饋和建議",
  "/Appinventory/language/index": "国际化管理",
  "/Appinventory/installPackgeUpload/index": "安裝包上傳",
  "/business-manage": "商業化管理",
  "/business-manage/coinMenu/index": "金幣套餐",
  "/business-manage/memberMenu/index": "會員套餐",
  "/business-manage/memberList/index": "會員列表",
  "/business-manage/orderGoodsList/index": "商品訂單列表",
  "/business-manage/memberBenefits/index": "會員權益",
  "/distributor-manage": "渠道商管理",
  "/distributor-manage/distributorlist/index": "渠道商列表",
  "/distributor-manage/distributor-scheme/index": "渠道商方案管理",
  "/distributor-statistics": "渠道數據統計",
  "/distributor-statistics/package-upload/index": "安裝包統計",
  "/withdraw": "提現管理",
  "/withdraw/distributor-withdraw/index": "渠道提現列表",
  "/withdraw/fission-withdraw/index": "裂變提現列表",
  "/withdraw/proxy-withdraw/index": "代理提現列表",
  "/admin/SMsMgr/index": "短信管理",
  "/admin/SMsMgr/smsTemplate": "短信模板",
  "/content/referralPool/index": "推薦池管理",
  "/business-manage/channelRevenueDetail/index": "收益明細表",
  "/burialevent": "埋点事件",
  "/burialevent/visualization/index": "可视化埋点",
  "/burialevent/attributestore/index": "属性库",
  "/video-manage/dismissReasons/index": "駁回原因",
  "/video-manage/dissentingPool/index": "異議池",

  // -----------------common start-------------------
  search: "搜索",
  reset: "重置",
  normal: "正常",
  disable: "禁用",
  check: "查看",
  edit: "編輯",
  add: "新增",
  change_password: "修改密碼",
  enable: "啟用",
  serial_number: "序號",
  start_time: "開始時間",
  end_time: "結束時間",
  updated_by: "更新人",
  update_time: "更新時間",
  operate: "操作",
  delete: "刪除",
  determine: "確定",
  cancel: "取消",
  successfully_deleted: "刪除成功",
  prompt: "提示",
  confirm_content: "此操作將永久刪除該數據, 是否繼續?",
  confirm_forbidden: "此操作將被禁用, 是否繼續?",
  confirm_enable: "此操作將啟用, 是否繼續?",
  confirm_disable:
    "確定禁用此關賬號？刪除之後該代理下的客戶產生收益將不再繼續統計?",
  please_enter: "請輸入",
  please_select: "請選擇",
  successfully_added: "新增成功",
  successfully_edited: "編輯成功",
  sort: "排序",
  status: "狀態",
  unit_day: "(天)",
  currency: "元",
  phone: "手機號",
  order_num: "訂單號",
  consume_type: "消費類型",
  pay_type: "支付類型",
  pay_way: "支付方式",
  goods_name: "商品名稱",
  pay_time: "支付時間",
  role: "角色",
  no_selected_items: "未有選中項",
  password: "密碼",
  confirm_password: "確認密碼",
  platform: "平臺",
  create_time: "創建時間",
  numbers_and_english: "數字和英文",
  user_name: "用戶名",
  existed: "已經存在",
  the_length_should_be: "長度應在",
  within_bits: "位以內",
  contact_info: "聯系方式",
  correct: "正確的",
  telephone: "電話",
  batch: "批量",
  batch_delete: "批量刪除",
  next_step: "下一步",
  export: "導出",
  add_time: "添加時間",
  verification_failed: "驗證不通過",
  settlement_method: "結算方式",
  index_title: "後臺管理系統 V 4.0.6",
  exit_full_screen: "退出全屏",
  full_screen: "全屏",
  home_page: "首頁",
  personal_information: "個人信息",
  exit_system: "退出系統",
  platform_switch_successful: "平臺切換成功",
  no_menu_found: "沒有發現菜單",
  close_other: "關閉其他",
  close_all: "關閉全部",
  more: "更多",
  examine: "審核",
  handle: "處理",
  schedule: "進度",
  withdrawal_method: "提現方式",
  withdrawal_amount: "提現金額",
  application_time: "申請時間",
  reason: "原因",
  pass: "通過",
  by_success: "通過成功",
  view_credentials: "查看憑證",
  proxy_account: "代理帳號",
  agent_name: "代理名稱",
  collection_account: "收款賬號",
  voucher: "憑證",

  // ---------route------------------------
  "/agent-manage/order-list/index": "代理提現訂單列表",

  // ---------权限管理------------------------
  // 用户管理
  select_all: "全選",
  please_enter_the_password_again: "請再次輸入密碼",
  the_two_passwords_not_match: "兩次輸入密碼不一致",
  real_name: "真實姓名",

  // ---------用户管理------------------------

  // ---------视频管理------------------------
  // 供应商视频上传
  upload_failed: "上傳失敗",
  draft: "草稿",

  // ---------系统管理------------------------

  // ---------内容管理------------------------

  // ---------举报与反馈------------------------

  // ---------营销中心------------------------

  // ---------APP管理------------------------
  // 我的管理
  picture_preview: "圖片預覽",
  feature_name: "功能名稱",
  affiliation_module: "所屬模塊",
  icon: "圖標",
  routing_path: "路由路徑",
  please_upload_pictures: "請上傳圖片",

  // ---------商业化管理------------------------
  // 金币套餐
  amount: "金額",
  number_purchases: "購買次數",
  obtaining_gold_coins: "獲得金幣",
  single_account_purchase_restrictions: "單賬號購買限制",

  // 会员套餐
  subscription_type: "訂閲類型",
  menu_name: "套餐名稱",
  fact_price: "實際價格",
  original_price: "原價",
  member_validity_period: "會員有效期",
  member_validity_period_day: "會員有效期（天）",
  rules_menu_name: "請輸入2-6个字符",

  // 会员列表
  nick_name: "昵稱",
  membership_type: "會員類型",
  pay_time: "支付時間",
  residual_interest: "剩餘權益",
  expire_time: "到期時間",

  // 商品订单列表
  total_income: "總收入",
  month_income: "本月收入",
  day_income: "當日收入",
  refund_amount: "退款金額",

  // 会员权益
  consumer_rights: "消費權益",
  consumer_rights_tip: "可對設置APP端獲取金幣或者獲取付費影片時的專屬折扣",
  recharge_coins: "充值金幣",
  discounts: "折",
  paid_video: "付費視頻",
  offline_download_Benefits: "离線下載權益",
  offline_download_Benefits_tip:
    "視頻離綫下載權益，指每個超級會員每個自然月可下載多少個視頻",
  moreonthly_offline_downloads: "每月离線下載次數",
  interactive_rights: "互動權益",
  interactive_rights_tip: "可設置是否可以進行評論,彈幕以及收藏的權限",
  comment: "評論",
  comment_tip: "SVIP的會員將可以對視頻進行評論發佈",
  barrage: "彈幕",
  barrage_tip: "SVIP的會員將可以對視頻進行彈幕發佈",
  collect: "收藏",
  collect_tip: "SVIP的會員將可以對視頻進行視頻收藏",
  save: "儲存",

  // ---------渠道商管理 -----------------------
  // 渠道商列表-
  full_name: "姓名",
  channel_merchant_account: "渠道商帳號",
  channel_merchant_name: "渠道商名稱",
  channel_merchant_list: "渠道商列表",
  scheme_permissions: "方案權限",
  number_of_owned_projects: "拥有項目數量",
  number_of_installation_packages_held: "持有安裝包數量",
  number_of_agents_owned: "拥有代理人數量",
  project_management: "項目管理",
  edit_password: "修改密碼",
  success_enabled: "啟用成功",
  success_disable: "禁用成功",
  login_password: "登入密碼",
  set_password: "設置6位密碼",
  set_scheme_permissions: "設置方案權限",
  settlement: "結算",
  programme: "方案",
  level_commission: "級金幣",
  illustrate: "說明",
  illustrate_content:
    "1~5級佣金將用於代理名下的C端用戶佣金分成,比如:當1~5級總和為40%,那麽剩下的60%將作為給平臺，渠道，代理按照選擇的方案來分成。",
  correct: "正確的",
  password_length: "密碼長度最少6位,最長20位",
  password_format: "密碼只能包含字母和數字",
  commission_rate: "金幣比例",
  commission_type: "金幣比例必須為正整數",
  commission_total: "佣金比例總和不能大於等於100",
  add_commission: "最多添加5級金幣",
  select_programme: "請至少選擇一個方案",
  modify_account: "修改帳號",
  verify_password: "兩次輸入的密碼不一致",

  // -渠道商管理 渠道商列表 渠道商项目
  channel_Details: "渠道詳情",
  channel_revenue: "渠道收益",
  agency_income: "代理收益",
  project_name: "項目名稱",
  system: "系統",
  version: "版本",
  total_commission: "總贡献佣金",
  withdrawn_commission: "已提現佣金",
  withdrawable_commission: "可提現佣金",
  agent_details: "代理詳情",
  settlement_plan: "結算方案",

  // -渠道商方案管理-
  cps_programme: "cps方案",
  cpa_programme: "cpa方案",
  platform_division: "平臺分成",
  channel_merchant_sharing: "渠道商分成",
  agent_sharing: "代理商分成",
  scheme_name: "方案名稱",
  set_percentage_value: "設置百分比值",
  rule: "規則",
  positive_integer: "請輸入正整數",
  input_total: "輸入值總和不能超過",
  three_input_total: "三者輸入的總和必須等於",

  // ---------渠道数据统计 ----------------------
  //--安装包统计-
  wrap_count: "安装包统计",
  acquisition_time: "獲取時間",
  invite_number: "邀請數量",
  agent_statistics_details: "代理商統計詳情",

  // ---------提现管理 ----------------------
  // -渠道提现列表-
  order_number: "訂單號",
  channel_withdrawal_list: "渠道提現列表",
  fail_to_succeed: "不通過成功",
  processing_results: "處理結果",
  success: "成功",
  fail: "失敗",
  failure_reason: "失敗原因",
  current_withdrawal: "當前結算共可提現金額",
  Channel_settlement: "渠道商結算方式",
  reason_for_failure: "不通過原因",
  noPass: "不通過",

  // -裂变提现列表-
  user_nickname: "用戶暱稱",
  user_account: "用戶帳號",
  audit_time: "審核時間",
  reject: "駁回",
  reject_success: "駁回成功",
  withdrawal_success: "提現成功",
  withdrawal_failed: "提現失敗",
  fission_withdrawal_list: "裂變提現列表",

  // -代理提现列表-
  withdrawal_path: "提現路徑",
  proxy_withdrawal_list: "代理提現列表",
  query: "查詢",
  enableThisVersion: "啟用此版本",
  downloadWebsite: "下載網址",
  abandoned: "廢棄",
};
