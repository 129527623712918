export const noTimeZoneUrls = [
  '/trade/web/tradeReport/channel/page',
  '/trade/web/tradeReport/channel/pageSum',
  '/trade/web/tradeReport/channel/export',
  '/trade/web/tradeReport/channel/payDetail/export',
  '/trade/web/tradeReport/channel/wdrlDetail/export',
  '/game/web/gameReport/game/page',
  '/game/web/gameReport/game/pageSum',
  '/game/web/gameReport/game/export',
  '/marketing/web/distributor/detail/queryPage',
  '/marketing/web/distributor/detail/export',
];

export default noTimeZoneUrls;
