import request from '@/api/axios';

// 分页查询渠道商提现审核订单信息
export function getExtensionWithdrawOrder(data) {
  return request({
    url: '/trade/web/extensionWithdrawOrder/extensionManualApproval/page',
    method: 'post',
    data: data,
  });
}

// 平台 - 审核提现订单 - 领取
export function receiveExtensionManualApproval(data) {
  return request({
    url: '/trade/web/extensionWithdrawOrder/audit/receiving/by-order-num',
    method: 'patch',
    data: data,
  });
}

// 平台 - 审核提现订单 - 退单
export function cancelExtensionManualApproval(data) {
  return request({
    url: '/trade/web/extensionWithdrawOrder/audit/cancel/by-order-num',
    method: 'patch',
    data: data,
  });
}

// 平台 - 审核提现订单 - 通过
export function agreeExtensionManualApproval(data) {
  return request({
    url: '/trade/web/extensionWithdrawOrder/audit/acceptation/by-order-num',
    method: 'patch',
    data: data,
  });
}

// 平台 - 审核提现订单 - 拒绝
export function rejectExtensionManualApproval(data) {
  return request({
    url: '/trade/web/extensionWithdrawOrder/audit/rejection/by-order-num',
    method: 'patch',
    data: data,
  });
}

// 查询提现审核订单数量
export function getExtensionWithdrawOrderCount(data) {
  return request({
    url: '/trade/web/extensionWithdrawOrder/extensionManualApproval/count',
    method: 'post',
    data: data,
  });
}
