import { getManualApprovalCount } from "@/api/admin/menu";
import store from "@/store";

const record = {
  state: {
    withdrawCount: 0,
  },
  actions: {
    async GetManualApprovalCount({ commit }) {
      // 如果有提現審核的搜尋權限，就會呈現對應的審核數量
      if (store.getters.permissions.trade_withdrawal_search_manual_approval) {
        const { data } = await getManualApprovalCount({});
        commit("SET_WITHDRAW_COUNT", data.data);
      }
    }
  },
  mutations: {
    SET_WITHDRAW_COUNT: (state, count) => {
      state.withdrawCount = count;
    },
  },
};
export default record;