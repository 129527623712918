export default {
  // ---------route------------------------
  '/user/details/index': '會員詳情',
  '/admin/user/index': '後台帳號管理',
  '/admin/role/index': '角色權限設定',
  '/admin/securityCenter/index': '認證機制設定',
  '/user/userList/index': '會員管理',
  '/business-manage/memberList/index': '超級會員列表',
  '/business-manage/paymentList/index': '支付管道設定',
  '/business-manage/bankCard/index': '銀行卡設定',
  '/withdraw/config/index': '提現設定',
  '/merchant': '銀商管理',
  '/merchant/paymentMethodList/index': '銀商支付通路設定',
  '/merchant/merchantList/index': '銀商列表',
  '/merchant/shopList/index': '商店清單',
  '/merchant/iconList/index': '預設圖示清單',
  '/merchant/record/index': '轉帳交易記錄',
  '/merchant/balanceChangeDetail/index': '銀商余額帳變紀錄',
  '/gameCenter': '遊戲中心',
  '/gameCenter/category/index': '遊戲分類',
  '/gameCenter/game/index': '遊戲上架',
  '/gameCenter/banner/index': '遊戲頁廣告上架',
  '/merchant/report/index': '銀商資料版',
  '/merchant/ask/index': '諮詢轉帳列表',
  '/record': '訂單管理',
  '/record/orderPaymentList/index': '付款訂單查詢',
  '/record/withdraw/index': '提現記錄查詢',
  '/admin/qaSetting/index': '客服設定',
  '/business-manage/membershipSet/index': '會員套餐',
  '/merchant/bankerRating/index': '銀商評分',
  '/record/tradeDetail/index': '資金交易明細',
  '/user/actionLog/index': '操作歷程',
  '/record/withdrawAudit/index': '提现审核',
  '/record/userFundsOverview/index': '用戶資金總覽',
  '/distributor-manage/distributor-withdrawAudit/index': '渠道商提現審核',

  // 遊戲中心
  addCategory: '新增分類',
  categoryName: '分類名稱',
  categoryIcon: '分類圖示',
  categorySort: '分類排序',
  isTop: '顯示於首頁清單',
  open: '開啟',
  close: '關閉',
  confirm: '確定',
  categoryTip1: '1.只能開啟5個分類顯示於App遊戲頁清單上。',
  categoryTip2:
    '2.每個分類清單內，上架遊戲需達4的倍數該分類才會正常顯示於App上，最多顯示8個遊戲。如超過8個會在列表上顯示更多按鈕。',
  searchGameName: '查詢遊戲名稱',
  searchGameCategeory: '查詢遊戲分類',
  reset: '重置',
  add: '新增',
  serialNumber: '序號',
  gameIcon: '遊戲icon',
  gameTitle: '遊戲標題',
  gameTitlePlaceholder: '請輸入遊戲標題',
  gameDesc: '遊戲描述',
  gameDescPlaceholder: '請輸入遊戲描述',
  gameCategeory: '遊戲分類',
  gameBetMultiplier: '遊戲投注額倍數',
  gameCategeoryPlaceholder: '請選擇遊戲分類',
  gameTagSetting: '遊戲標籤設定',
  gameTagSettingPlaceholder: '請輸入標籤設定',
  gameLink: '遊戲連結',
  gameLinkMsg: '請輸入遊戲連結',
  gameWeight: '遊戲權重',
  gameWeightPlaceholder: '請輸入權重值1~100',
  gameCategoryTip1: '遊戲權重設定會影響到遊戲在各別分類表單裡的排名。1 為最高排序，後依序下降。',
  showInHotGame: '顯示在熱門遊戲',
  gameCategoryTip2: '熱門遊戲需開啟滿8個，App才能正常顯示熱門排行。8個以下無法顯示。',
  operate: '操作',
  delDialogMsg1: '是否確認刪除該遊戲',
  delDialogConfirmText: '確認刪除',
  delDialogCancelText: '我再想',
  deleteSuccess: '刪除成功',
  editSuccess: '編輯成功',
  imgOnlyPng: '上傳圖片只能是 PNG 格式!',
  gameCategoryModalMsg: '此遊戲分類仍有上架遊戲存在，是否確認刪除整個分類遊戲內容',
  categorySortPlaceholder: '請輸入分類排序',
  categoryNamePlaceholder: '請輸入分類名稱',
  categoryIconUrlPlaceholder: '請上傳分類圖示',
  successCreate: '創建成功',
  advertName: '廣告名稱',
  startPlaceholder: '開始日期',
  endPlaceholder: '結束日期',
  advertState: '廣告狀態',
  enable: '啟用',
  disable: '停用',
  advertImg: '廣告圖片',
  advertSort: '廣告排序',
  startTime: '開始時間',
  endTime: '結束時間',
  forwardUrl: '跳轉位址',
  creater: '創建人',
  delDialogMsg2: '是否確認刪除該遊戲頁廣告',
  advertNamePlaceholder: '請輸入廣告名稱',
  advertImgUploadTip: '尺寸：990 * 450 格式檔案：png',
  addedTime: '上架時間',
  addedTimePlaceholder: '請輸入上架時間',
  advertSortPlaceholder: '請輸入廣告排序',
  forwardType: '跳轉類型',
  outsideLink: '外鏈',
  insideLink: '內鏈',
  forwardUrlPlaceholder: '請輸入跳轉位址',
  advertIconUrlPlaceholder: '請上傳廣告圖片',
  gameIconPlaceholder: '請上傳遊戲icon圖片',

  //提現
  queryUsername: '查詢使用者暱稱',
  queryPhone: '查詢手機號碼',
  moneyRangeMin: '申請金額最小值',
  moneyRangeMax: '申請金額最大值',
  actionStartTime: '申請開始時間',
  actionEndTime: '申請結束時間',
  goodsOrderNum: '商品訂單號碼',
  paymentOrderNum: '支付訂單號碼',
  withdrawBank: '提現銀行',
  bankCardNo: '銀行卡號',
  query: '查詢',
  bankPlaceholder: '選擇提款銀行',
  withdrawTime: '選擇提現時間',
  statusPlaceholder: '選擇狀態',
  withdrawUserName: '提款姓名',
  applicationTime: '提現時間',
  applicationMoney: '申請金額',
  arrivalTime: '到帳時間',
  arrivalMoney: '到帳金額',
  extraMoney: '返利',
  goods: '商品',
  tradeMoney: '交易金幣數',
  tradeAfterMoney: '交易後餘額',
  orderName: '訂單名稱',
  tradeResult: '交易結果',
  tradeTime: '交易時間',
  bankName: '銀行名稱',
  cardName: '持卡人姓名',
  bindTime: '綁定時間',
  bankCardDetail: '銀行卡詳情',
  plus: '加',
  decrease: '扣',
  remark: '備註',
  remarkPlaceHolder: '請輸入備註',
  moneyPlaceHolder: '請輸入金額',
  withdrawName: '提現標題',
  limitType: '限制類型',
  limitTypePlaceholder: '請輸入限制類型',
  limitRange: '限制金額範圍',
  limitValue: '限制固定金額',
  minLimit: '限制金額最小值',
  maxLimit: '限制金額最大值',
  limitValuePlaceholder: '請輸入限制固定金額',
  withdrawBonus: '提現返利比例',
  withdrawBonusPlaceholder: '請輸入提現紅利比例',
  activityContent: '活動內容',
  contentPlaceholder: '請輸入活動內容',
  specialPoint: '特點內容',
  specialPointPlaceholder: '請輸入特點內容',
  withdrawFixedMoney: '提現固定金額',
  withdrawFixedMoneyPlaceholder: '請輸入提現固定金額',
  normalContent: '普通提現標題',
  normalContentPlaceholder: '請輸入普通提現標題',
  reserveContent: '預約提現標題',
  reserveContentPlaceholder: '請輸入預約提現標題',
  resTimeAndBonus: '預約時限與加送',
  reserve: '預約',
  hour: '小時',
  extraBonus: '加送',
  withdrawNamePlaceholder: '請輸入提現名稱',
  imgSize64: '尺寸：64*64 容量:100kb以下 格式檔案：png',
  imgSize72: '尺寸：72*72 容量:100kb以下 格式檔案：png',
  imgSize128: '尺寸：128*128 容量:100kb以下 格式檔案：png',
  imgSize192: '尺寸：192*192 容量:100kb以下 格式檔案：png',
  clickUpload: '點擊上傳',
  appoSetting: '預約時間',
  timeLimit: '時限',
  timeLimitPlaceholder: '請輸入預約時限',
  give: '贈送',
  pleaseEnterRebateRatio: '請輸入返利比例',
  giveTip: '最多可增加到9個',
  appWithdrawMoney: 'App提現金金額',
  limitFixedPreview: '固定金額選單(預覽)',
  customRange: '自行輸入範圍',
  fixedMoney: '固定金額',

  rangeMoney: '範圍',
  minMaxValueError: '限制金額範圍最大值不能小於最小值',
  appoSettingError: '預約設定時限或贈送數值未填寫完整',
  imageLimitUploadError: '只能傳入一個圖片,請刪除已上傳的圖片後上傳',
  imageTypeJpgPngError: '上傳圖片只能是 JPG/PNG 格式!',
  imageTypePngError: '上傳圖片只能是 PNG 格式!',
  imageSizeError: '上傳圖片大小不能超過 100KB!',
  imageSizeError2: '上傳圖片大小不能超過 5MB!',
  imageWidthHeight64Error: '上傳圖片尺寸不符合,只能是64x64!',
  imageWidthHeight72Error: '上傳圖片尺寸不符合,只能是72x72!',
  imageWidthHeight128Error: '上傳圖片尺寸不符合,只能是128x128!',
  imageWidthHeight192Error: '上傳圖片尺寸不符合,只能是192x192!',
  imageWidthHeight990x450Error: '上傳圖片尺寸不符合,只能是990x450!',
  withdrawTitle: '提現方式',
  moneyTip: '最多11個 ex:100,200,300...etc',
  record: '記錄',
  transferMoney: '轉錢',
  done: '完成',
  userName: '使用者名稱',
  merchantName: '銀商名稱',
  askStatus: '譫詢狀態',
  createTime: '建立時間',
  updateTime: '更新時間',
  selectTimeType: '選擇時間類別',
  applyMoneyMin: '到帳金額最小值',
  applyMoneyMax: '到帳金額最大值',
  selectOrderStatus: '選擇商品訂單狀態',
  selectPayType: '選擇付款方式',
  selectPayStatus: '選擇支付訂單狀態',
  bankCode: '銀行ID',
  Operator: '操作人',
  OperatorTime: '操作時間',
  bankCodePlaceholder: '請輸入銀行ID',
  bankNamePlaceholder: '請輸入銀行名稱',
  goodsArrivalTime: '商品到帳時間',
  goodsarrivalMoney: '商品到帳金額',
  payArrivalTime: '支付到帳時間',
  payArrivalMoney: '支付到帳金額',
  goodOrderStatus: '商品訂單狀態',
  payOrderStatus: '支付訂單狀態',
  fillOrder: '補單',
  setBalance: '調整金額',
  failTitle: '調整訂單狀態失敗',
  fillOrderTitle: '補單',
  setBalanceTitle: '調整金額',
  setRange: '調整範圍',
  ALL: '全部',
  TRADE_ORDER: '商品訂單',
  PAY_RECORD: '付款訂單',
  oldMoney: '原本金額',
  afterMoney: '調整後金額',
  COMPLETED: '已完成',
  UNPROCESSED: '未處理',
  PROCESSING: '處理中',
  bankImg: '銀行圖片',
  bankImgPlaceholder: '請上傳銀行圖片',
  adjustMerchantBalance: '調整銀商餘額',
  transferToMerchant: '轉錢給銀商',
  balance: '餘額',
  imChannel: '聊天管道',
  imChannelPlaceholder: '請輸入聊天管道',
  imToken: '聊天Token',
  imTokenPlaceholder: '請輸入聊天Token',
  changeAmountPlaceholder: '請輸入變動金額',
  userData: '使用者資訊',
  merchantData: '銀商資訊',
  addMoney: '增加金額',
  addMoneyPlaceholder: '請輸入增加金額',
  merchantUserName: '銀商使用者名稱',
  targetUserName: '目標使用者名稱',
  type: '型別',
  tranType: '轉帳類型',
  tranToMerchant: '轉帳至銀商',
  tranToUser: '轉帳至使用者',
  applySum: '總申請金額',
  receiveSum: '總到帳金額',
  receiveGoodsSum: '商品訂單總到帳金額',
  receivePaySum: '支付訂單總到帳金額',
  appoTime: '預約時間',
  consume_typePlaceholder: '選擇消費型別',
  customMoney: '自訂金額',
  qaAddress: '客服位置',
  qaURL: '客服連結',
  INIT: '請求建立',
  WITHDRAWING: '申請中',
  FAIL: '失敗',
  COMPLETE: '成功',
  COMPLETE_CHANGE: '已提現，但金額有異動',
  moblie: '用戶手機號碼',
  note: '備註',
  payAmount: '交易金額',
  walletBalance: '錢包餘額',
  gold: '金幣',
  cumUse: '累計使用',
  cumIncome: '累計收入',
  Refund: '退款',
  gameBalance: '遊戲幣餘額',
  APPLY: '下單時間',
  GOODS: '商品到帳時間',
  PAY: '支付到帳時間',
  day: '本日',
  month: '本月',
  day7: '7天',
  day30: '30天',
  day90: '90天',
  lastday: '昨日',
  lastmonth: '昨月',
  lastday7: '昨7天',
  lastday30: '昨30天',
  lastday90: '昨90天',
  accountBalance: '帳戶餘額',
  processAmount: '處理金額數',
  completedOrder: '處理訂單數',
  completedUser: '處理使用者數',
  unprocessedOrder: '未處理完成單數',
  compareValue: '比較值',
  orderNumber: '訂單數',
  memberNumber: '使用者數',
  merchantReportExcel: '銀商資料報表',
  exportData: '匯出資料',
  dateRangeDay90Error: '日期自定區間不能超過90天',
  dateRangeDay31Error: '日期自定區間不能超過31天',
  dayRange90Tip: '最長篩選時間為90天',
  whiteStatus: '白名單',
  yes: '是',
  no: '否',
  detail: '詳情',
  withdrawDetail: '提現詳情',
  delMerchantRoleError: '銀商角色無法刪除',
  paymentName: '付款名稱',
  paymentNamePlaceholder: '請輸入付款名稱',
  paymentType: '付款方式',
  paymentTypePlaceholder: '請輸入付款方式',
  icon: '圖示',
  uploadIcon: '上傳圖示',
  imageWidthHeight64Error2: '圖片上傳限制：圖片尺寸需為64x64，圖檔大小需100Kb以下。',
  ConsumptionWay: '消費方式',
  ConsumptionWayPlaceholder: '請輸入消費方式',
  rechargeBonusRate: '儲值紅利比例',
  rechargeBonusRatePlaceholder: '請輸入儲值紅利比例',
  rechargeFixedAmount: '儲值固定金額',
  rechargeFixedAmountPlaceholder: '請輸入儲值固定金額',
  isEnable: '是否啟用',
  appRechagerAmount: 'App儲值金額',
  create: '建立',
  payNamePlaceholder: '請輸入付款名稱',
  paymentWayPlaceholder: '請選擇付款方式',
  costTypePlaceholder: '請選擇消費類型',
  costType: '消費型別',
  status: '狀態',
  updater: '更新人',
  edit: '編輯',
  delete: '刪除',
  delConfirmText: '此操作將永久刪除該資料, 是否繼續?',
  tips: '提示',
  cancel: '取消',
  cancelMsg: '已取消刪除',
  cantUse: '停用',
  oldBalance: '原始餘額',
  newBalance: '新餘額',
  changeAmount: '帳變金額',
  createdUsername: '建立人',
  adminChangeBalance: '管理員增減餘額',
  adminToMerchant: '轉帳至銀商',
  merchantToMerchant: '從銀商轉帳',
  merchantToUser: '轉帳至使用者',
  activityNormal: '活動正常',
  activityInvalid: '活動過期',
  noActivity: '無活動',
  activityNoStarted: '未開始',
  discountPrice: '特價價格',
  PurchaseActivityDate: '購買次數活動日期',
  actualPrice: '實際價格',
  hasActivity: '套餐活動',
  notActivity: '非活動',
  ActivitySetting: '活動設定',
  PackageDate: '套餐上架日期',
  buyLimitCountMsg: '請選擇請輸入限制次數',
  goodTypeMsg: '請選擇連訂閱類型',
  hasActivityMsg: '請選擇連活動類型',
  enterDateMsg: '請選擇日期',
  verifyNotPass: '驗證不通過',
  discountPriceYuanErrorMsg: '請輸入實際價格',
  priceYuanErrorMsg: '請輸入特價價格',
  menuNameErrorMsg: '請輸入套件名稱',
  lastTimeErrorMsg: '請輸入會員效期',
  sortErrorMsg: '請輸入排序',
  shopName: '店家名稱',
  shopNamePlaceholder: '請輸入店家名稱',
  merchantNamePlaceholder: '請輸入銀商名稱',
  scoreNotEmpty: '評分不能為空白',
  scorePlaceholder: '請輸入評分',
  monthScore: '每月評價',
  monthScoreNotEmpty: '月評價不能為空',
  isOpenClose: '是否開啟',
  updateSort: '更新排序',
  openSuccess: '開啟成功',
  closeSuccess: '關閉成功',
  limitPurchaseTimes: '限制購買次數',
  iconPlaceholder: '請輸入圖示',
  imageUploadTip128: '圖片上傳限制：圖片尺寸需為128x128，圖檔大小需100Kb以下。',
  shopTag: '商店標籤',
  shopTagPlaceholder: '請輸入店舖標籤',
  defaultIcon: '預設圖示',
  filterDate: '篩選時間',
  lastPeriod: '上期',
  userID: '用戶ID',
  merchantOrgchart: '{msg} 組織圖',
  merchant_id: '銀商',
  shop_id: '店家',
  rankList: '排行榜',
  score: '評分次數',
  avg: '平均分數',
  service_attitude: '服務態度',
  recharge_time: '儲值速度',
  threeMonth: '近三個月',
  TOP: '前三名',
  LAST: '後三名',
  userScore: '使用者評分錶',
  merchantPlaceholder: '請選銀商',
  shopPlaceholder: '請選擇店家',
  tradeType: '交易類型',
  platformAccount: '平台帳戶',
  gameAccount: '遊戲帳號',
  accountType: '帳戶類型',
  permissionDenied: '目前操作沒有權限',
  actionType: '操作類型',
  addPopcoin: '加啪啪幣',
  deductPopcoin: '扣啪啪幣',
  rechargeCompensationOrder: '充值補單',
  rechargeFailure: '充值失敗',
  rechargeAdjustAmount: '充值調整金額',
  withdrawalCompensationOrder: '提現補單',
  withdrawalFailure: '提現失敗',
  withdrawalAdjustAmount: '提現調整金額',
  disableWithdrawal: '禁用提現',
  enableWithdrawal: '解禁提現',
  transferToUserSuccess: '轉錢成功',
  disableGameAccount: '停用遊戲帳號',
  enableGameAccount: '解禁遊戲帳號',
  disableWithdraw: '停用提現',
  enableWithdraw: '解禁提現',
  gameAccountStatus: '遊戲帳號狀態',
  withdrawStatus: '提現狀態',
  disableGameAccountMsg: "是否確定停用使用者'{msg}'遊戲賬號",
  disableWithdrawMsg: "是否確定停用'{msg}'提現",
  confirmDisable: '確認停用',
  askList: '咨询列表',
  setTransfer: '指定转账',
  userIdRequest: '請輸入用戶ID',
  RECHARGE: '儲值',
  RECHARGE_BONUS: '充值贈送金',
  RECHARGE_AMOUNT_CHANGE: '充值調整',
  RECHARGE_FAIL: '充值退款',
  BUY_SUBSTANCE: '購買視頻',
  BUY_SVIP: '購買會員',
  GOLD_TRANSFER_GAME: '{0}轉遊戲幣',
  WALLER_TRANSFER_GOLD: '{0}轉{1}',
  GAME_TRANSFER_GOLD: '遊戲幣轉{0}',
  WITHDRAWAL: '提現',
  WITHDRAWAL_BONUS_CHANGE: '提現贈送金調整',
  WITHDRAWAL_BONUS: '提現贈送金',
  BACKSTAGE_ADD_GOLD: '平台加{0}',
  BACKSTAGE_DEDUCTION_GOLD: '平台扣{0}',
  GAME_TRANSFER_WALLET: '遊戲幣轉{0}',
  normal: '正常',
  banSuccess: '禁用成功',
  unlockedSuccess: '解禁成功',
  queryUserId: '查詢用戶ID',
  nowWalletBalance: '當前{0}餘額',
  nowGameCoinBalance: '當前遊戲幣餘額',
  recharge: '儲值',
  rechargeToolTips: '充值=充值+法幣購買會員',
  withdrawMoney: '提現',
  bet: '投注',
  buyVideo: '購買視頻',
  loginIPHistoty: '登录IP历程',
  waitAudit: '待审核',
  auditLog: '审核纪录',
  selectWithdrawType: '选取领取状态',
  bankNoTimes: '银行卡号绑定次数',
  gameProfitLoss: '用戶游戏币盈亏比例',
  deviceNoIsOk: '设备码是否正常',
  IPIsOk: 'IP地址是否正常',
  get: '领取',
  getTime: '领取时间',
  getUser: '领取人',
  backOrder: '退单',
  reject: '拒绝',
  allow: '通过',
  selectAuditAccount: '选择审核账号',
  processDoneTime: '处理完成时间',
  auditUser: '审核人',
  waitAuditDesc: '上次提現後的遊戲幣盈虧/轉入遊戲幣金額-遊戲幣轉啪啪幣金額，若距上次提現超90日，則以90日為主',
  deviceDetail: '设备码详情',
  deviceNo: '设备码',
  WithdrawalerName: '提款人姓名',
  loginIP: '登录IP',
  loginIPRecent: '近一次登入IP',
  NOT_GET: '未领取',
  GET_DONE: '已领取',
  backNoDetail: '银行卡号详情',
  loginTime: '登录时间',
  loginIPDetail: '登录IP详情',
  auditStatus: '审核状态',
  auditRemark: '审核备注',
  NOT_WITHDRAWAL: '申请中',
  auditType: '审核方式',
  AUTO: '自动',
  ARTIFICIAL: '人工',
  SYSTEM: '系统',
  auditor: '選擇審核人',
  chooseOperatedOption: '選擇操作方式',
  SET_BALANCE: '調整金額',
  FILL_ORDER: '補單',
  theCurrentCardholderName: '當前持卡人姓名',
  modifyCardholderNameBankCard: '修改銀行卡的持卡人名稱',
  modifyNameCardman: '修改持卡人姓名',
  changeTime: '異動時間',
  pleaseSelectBankcardStatus: '請選擇銀行卡狀態',
  all: '全部',
  effect: '有效',
  userNickname: '用戶暱稱',
  nameOfTheCardholderAfterModification: '修改後持卡人姓名',
  enterNameCardholderAfterModification: '請輸入修改後持卡人姓名',
  modifyTheCardholderOfOtherBankCardsSimultaneously: '同步修改其他銀行卡的持卡人',
  deleteAllBankCards: '刪除舊銀行卡並修改持卡人姓名',
  cardholderCantbeSame: '持卡人姓名相同無法修改',
  noPerms: '沒有權限查詢',
  userInfo: '用戶基本訊息',
  membership: '會員身份',
  memberRemainingDays: '會員剩餘天數',
  setSvip: '設定超級會員',
  setSvipDays: '設置超級會員天數',
  pleaseSetSvipDays: '請輸入會員天數',
  settingSuccess: '設定成功',
  PAYING: '未支付',
  FAILURE: '失敗',
  CANCELED: '失敗',
  NOT_PAY: '待支付',
  rechargeTimeLimit: '充值時間限制',
  guideChannel: '引導渠道',
  realNameSystem: '實名制',
  uploadCertificate: '上傳憑證',
  whetherToEnable: '是否啟用',
  pleaseUploadImage: '請上圖示',
  paymentIcon: '支付icon',
  uploadReturnOrder: '上傳回執單',
  revenueAndExpenditure: '收支流水',
  paymentLimit: '支付時間限制',
  pleaseEnterLimitTime: '請輸入限制時間',
  pleaseSelectGuidanceChannels: '請選引導渠道',
  minute: '分鐘',
  imageWidthHeightErrorFormat: '上傳圖片尺寸不符合,只能是{0}x{1}!',
  imgFormatText: '尺寸：{0}*{1}\n容量：5MB以下\n格式檔案：png',
  uploadImg: '上傳圖示',
  upPoint: '上分',
  downPoint: '下分',
  bonus: '返利',
  withdrawalBonus: '提現返利',
  rechargeBonus: '充值返利',
  addPoint: '補分',
  RECHARGE_AMOUNT_CHANGE_ADD: '充值補分',
  WITHDRAWAL_BONUS_CHANGE_ADD: '提現補分',
  deduction: '扣分',
  RECHARGE_AMOUNT_CHANGE_DEDUCTION: '充值扣分',
  WITHDRAWAL_BONUS_CHANGE_DEDUCTION: '提現扣分',
  order: '訂單',
  WITHDRAWAL_FAIL_RETURN: '提現失敗',
  FRIENDS_CONSUMPTION_REBATE: '好友消費返利',
  withdraw_WITHDRAWING: '處理中',
  withdraw_FAIL: '失敗',
  withdraw_COMPLETE: '成功',
  withdraw_NOT_WITHDRAWAL: '申請中',
  withdrawAmountType: '提現金額類型',
  fixed: '固定',
  fixedMoneyPlaceholder: '請輸入固定金額',
  amountRange: '金額範圍',
  amountMinLimit: '金額最小值',
  amountMaxLimit: '金額最大值',
  withdrawAmountWay: '提現金額方式',
  fixedMoneyAndOtherMoney: '固定金額＆其他金額',
  enterAmount: '輸入金額',
  withdrawSwitch: '提現開關',
  reminderTimeInterval: '催單時間間距',
  withdrawing: '出款中',
  disbursed: '已出款',
  pleaseEnter: '請輸入',
  timeRange: '間距範圍：1--9999',
  estimatedArrivalTime: '預計到帳時間',
  normalWithdrawSwitch: '普通提現開關',
  reserveWithdrawSwitch: '預約提現開關',
  rebate: '返利',
  withdrawalMethod: '提現方式',
  pleaseSelectWithdrawalMethod: '請選擇提現方式',
  selectPaymentMethod: '選擇收款方式',
  paymentMethod: '收款方式',
  sdl_tunnel_id: 'SDL通道編號',
  NORMAL: '正常',
  gameId: '遊戲ID',
  gameName: '遊戲名稱',
  gameImg: '遊戲圖標',
  addOne: '添加',
  gameLobby: '遊戲大廳',
  deleteAllBankCard: '刪除全部銀行卡',
  date: '日期',
  paymentCount: '支付訂單筆數',
  paymentAmount: '支付訂單金額',
  withdrawCount: '提現訂購筆數',
  withdrawAmount: '提現訂單金額',
  transferOut: '下分',
  transferIn: '上分',
  gameToMsg: '(遊戲幣轉{msg})',
  msgToGame: '({msg}轉遊戲幣)',
  remainAmount: '剩餘額度',
  betAmount: '投注額',
  effectBetAmount: '有效投注金額',
  agShare: '代理佔成',
  myProfit: '我的獲利',
  actProfit: '實際獲利',
  gameToken: '遊戲彩金',
  channel: '通道',
  withdrawUserType: '提現使用者型別',
  agentWithdraw: '渠道商提現',
  userWithdraw: '用戶提現',
  sumPaymentCount: '總支付訂單單數',
  sumPaymentAmount: '總支付訂單金額',
  sumWithdrawCount: '總提現訂單單數',
  sumWithdrawAmount: '總提現訂單金額',
  exportWithdrawDetail: '導出提現明細',
  exportPaymentDetail: '導出支付明細',
  sumTransferOut: '總上分',
  sumTransferIn: '總下分',
  sumBetAmount: '總投注金額',
  sumEffectBetAmount: '有效投注總額',
  sumMyProfit: '總我的利潤',
  sumActProfit: '總實際獲利',
  everyDayDetail: '每日詳情',
  exportTransferOutDetail: '導出上分明細',
  exportTransferInDetail: '導出下分明細',
  wayReport: '通道報表',
  paymentReport: '支付明細報表',
  withdrawReport: '提現明細報表',
  gameReport: '遊戲報表',
  transferOutReport: '上分明細報表',
  transferInReport: '下分明細報表',
  NumberOfDays: '天數',
  permanent: '永久',
  ARRIVAL_TIME: '到帳時間',
  operationLog: '操作日誌',
  distributorWithdrawOperationLog: '渠道商提現操作日誌',
  distributorWithdrawSetting: '渠道商提現設定',
  withdrawalAmountRange: '提現金額區間',
  withdrawalMoneyPlaceholder: '請輸入提現金額',
  accountStatus: '帳號狀態',
  disabled: '禁用',
  WITHDRAWAL_CHANGE: '提現金額調整',
  returnGameToken: '返遊戲彩金',
  returnGameTokenErrorMsg: '請輸入返遊戲彩金',
  memberPackageIsEnable: '會員套餐啟用中，不可刪除',
  gameTokenBalance: '遊戲彩金餘額',
  addGameToken: '加遊戲彩金',
  deductGameToken: '扣遊戲彩金',
  gameTokenCount: '遊戲彩金數',
  officialCertification: '官方認證',
  batchDisabledAccountContent: '是否確定禁用{0}個賬號?',
  batchEnabledAccountContent: '是否確定解禁{0}個賬號?',
  serviceTitlePlaceholder: '限20個字',
  disableAccountNumber: '批量禁用賬號',
  batchLiftingAccount: '批量解禁賬號',
  customerService: '客服通知',
  CustomerServiceNotificationTitle: '客服通知標題',
  disableAccount: '禁用賬號',
  liftingAccount: '解禁賬號',
  resetPassword: '重置密碼',
  setMobilePhoneNumber: '設置手機號',
  setAvatar: '設置頭像',
  setTheNickname: '設置暱稱',
  userDetailMobile: '手機號',
  registrationTime: '註冊時間',
  age: '年齡',
  gender: '性別',
  area: '地區',
  numberOfReports: '被舉報數',
  male: '男',
  female: '女',
  secrecy: '保密',
  disableUserMsg: "是否確定禁用'{msg}'賬號",
  resetPasswordSuccess: '重置密碼成功',
  nickNamePlaceholder: '請輸入暱稱',
  nickNameMinString: '暱稱長度不能少於2個字符',
  nickNameMaxString: '暱稱長度不能大於8個字符',
  copy: '複製',
  copySuccess: '複製成功',
  mustSelectOne: '請選一項',
  noLoginDay: '未登入天數',
  pleaseInputMobile: '請輸入登錄手機號',
  mobileErrorMsg: '手機號格式不正確',
  previewAvatar: '頭像預覽',
  imgSameScaleTip: '請上傳寬高比為1 : 1的文件',
  proxyDataOfWebsite: '全民代理網站數據',
  numberOfVisitors: '網站開啟數',
  downloadTimes: '點擊下載次數',
  numberOfNewUsers: '推廣碼下載新增用戶數',
  currentRatio: '当前分成占比',
  subscribersCount: '充电用户数',
  gameRevenue: '游戏收入',
  currencyType: '幣種',
  sourcesOfFunds: '資金來源',
  aiDataSetting: 'A I 輔助生成數據設置',
  aiScheduleSetting: 'A I 輔助生成排程設置',
  minValue: '最小值',
  maxValue: '最大值',
  switch: '開關',
  closeWhenOverThreshold: '原數據超越此值則自動跳過執行',
  to: '至',
  thresholdPlaceholder: '1-999999',
  SUBSTANCE_COMMENT: '評論數',
  SUBSTANCE_LIKE: '點贊數',
  SUBSTANCE_PLAY: '觀看數',
  SUBSTANCE_COLLECT: '收藏數',
  SUBSTANCE_DOWNLOAD: '下載數',
  SUBSTANCE_FORWARD: '轉發數',
  everyDay: '每日',
  H: '時',
  M: '分',
  or: '或',
  every: '每',
  everyTime: '每次',
  count: '筆',
  repeatImplement: '重複執行',
  hourList: '0-23',
  minuteList: '0-59',
  minMaxDataError: '範圍最大值不能小於最小值',
  overThresholdError: '最小與最大值不可大於自動關閉欄位值',
  aiDataSettingTips1:
    '1.相關次數將在兩個數值間距中隨機產生一個數值,且可自行選擇開啟或關閉某一種數值<br/>2.單位與數量<br/>1-999999 不可為0或負數,空位<br/>3.且最小與最大值不可大於自動關閉欄位值',
  aiScheduleSettingTips1: '单选勾选开关,二择一<br/>下拉選單<br/>时间为023时,与059分',
  aiScheduleSettingTips2: '單位與數量<br/>1-30筆 不可為0或負數,空位',
  aiScheduleSettingTips3:
    '此開關開啟時 可以重複執行輔助生成設置中所有數據且已執行過的視頻,關閉則不重複執行',
  aiScheduleSettingWarning: '單位與數量 1-30筆 不可為0或負數,空位',
  alreadySend: '已發送',
  waitSend: '未發送',
  cancelSend: '取消發送',
  imgSize363x150: '尺寸：363*150 容量:5m以下 格式檔案：png',
  imgSize363x150HTML: '尺寸：363*150<br>容量：5m以下<br>格式檔案：png',
  addSuccess: '操作成功',
  sendMessage: '發送消息',
  userMessage: '用戶消息',
  enterMessageTitle: '請輸入消息標題',
  selectMessageStatus: '選擇消息狀態',
  pushTime: '推送時間',
  messageTitle: '消息標題',
  sender: '發送人',
  targetUser: '目標用戶',
  messageStatus: '消息狀態',
  readRate: '讀取率',
  checkIt: '查看',
  content: '內容',
  imgIcon: '圖示',
  graphicJump: '圖示跳轉',
  selectGraphicJump: '選擇圖示跳轉',
  messageType: '消息類別',
  systemMessage: '系統消息',
  allUsers: '全體用戶',
  superVipUsers: '超級會員用戶',
  partialUsers: '部分用戶',
  enterPartialUserID: '請輸入部分用戶ID',
  selectedUserCount: '目前選擇用戶數量為: ',
  batchUpload: '批量上傳',
  downloadExample: '下載範例',
  push: '推播',
  messageCenterNotification: '消息中心通知',
  pushImmediately: '立即推送',
  schedulePush: '預約推送',
  scheduleTime: '預約時間',
  selectDateAndTime: '選擇日期選擇時間',
  noRedirect: '不轉跳',
  downloadList: '下載名單',
  immediately: '立刻',
  scheduled: '定時',
  pleaseInputContent: '請輸入內容',
  pushWay: '推送方式',
  pleaseInputLink: '請輸入連結',
  pleaseInputPushWay: '選擇推送方式',
  onlyUploadXLSX: '只能上傳後綴是.xlsx',
  pleaseInputMsgType: '選擇消息類型',
  pleaseInputReadStatus: '選擇讀取狀態',
  msgType: '消息類型',
  msgContent: '消息內容',
  readAndUnread: '已讀/未讀',
  previewMsg: '消息預覽',
  more: '更多',
  KEFU: '客服',
  isReaded: '已讀',
  unread: '未讀',
  messageCenterNotificationAndPush: '推播、消息中心通知',
  imgLink: '圖示連結',
  clickDownload: '點擊下載名單',
  other: '其他',
  noNowTime: '不可設定當下時間',
  sendList: '發送名單',
  parameterName: '參數名稱',
  parameterValue: '參數值',
  logoutDays: '長時間未登錄天數',
  logoutDaysCondition:
    '該參數控制提現審核長時間未登錄異常條件，最大值3650日，輸入0等於該判斷關閉。',
  suspectLists: '提款姓名嫌疑名單',
  suspectListsCondition: '該參數控制提現審核提款人姓名異常條件',
  dayUnit: '天',
  pleaseEnterLogoutDays: '請輸入長時間未登錄天數',
  payName: '付款人姓名',
  phoneNumber: '手機號碼',
  loginAreaIsNormal: '登錄地區是否正常',
  logoutForLongTime: '長時間未登錄',
  paymentOrderIsNormal: '支付訂單是否正常',
  withdrawerDetail: '提款人姓名詳情',
  loginAreaDetail: '登錄地區詳情',
  sinceLastLoginTime: '距離上次登錄時長',
  paymentOrderDetail: '支付訂單詳情',
  lastTenApplication: '近10次申請總額',
  lastTenDeposits: '近10次到賬總額',
  lastTenArrivals: '近10次到賬率',
  enableThisVersion: '啟用此版本',
  downloadWebsite: '下载网址',
  abandoned: '废弃',
  clickPercent: '點擊率',
  installPercent: '安裝率',
  downloadAddUser: '下載新增使用者數',
  officalData: '官方推廣頁數據',
  installTechData: '安裝教學頁資料',
  inviteSumData: '推廣總資料',
  selectDate: '選擇時間',
  paymentOrderIsNormalTips: '不正常定義：近10次充值到賬率低於20%或付款人姓名不同者',
  selectMessageType: '選擇消息類型',
  chooseASender: '選擇發送人',
  manual: '手動',
  platformAnnouncement: '平台公告',
  nationalAgent: '全民代理',
  gamingAgent: '遊戲代理',
  thread: '提現消息',
  rechargeMessage: '充值消息',
  gamingAgentIdentity: '遊戲代理身份',
  proxyIdentity: '代理身份',
  superiorAgentID: '上級代理ID',
  agentBindingTime: '代理綁定時間',
  totalNumberOfLowerAgents: '下級代理總數量',
  directSubUserNumber: '直屬下級數量',
  directUserNumber: '直屬用戶數量',
  signedContracts: '已簽約數量',
  notSetContracts: '未設置契約數量',
  awaitingContracts: '待簽約數量',
  levelOne: '一級代理',
  levelTwo: '二級代理',
  levelThree: '三級代理',
  levelFour: '四級代理',
  levelFive: '五級代理',
  directUser: '直屬用戶',
  frequency: '次',
  domainNamePosition: '域名位置',
  domainNameAddress: '域名地址',
  pleaseEnterDomainName: '请输入域名',
  newAgent: '新增代理',
  promotionLink1: '推廣鏈接1',
  promotionLink2: '推廣鏈接2',
  pleaseEnterDomainName2: '請輸入{0}的域名',
  abandonedHistorical: '廢棄歷史清單',
  appDomainBame: 'app域名',
  abandon: '廢棄',
  maxTenItems: '最多僅創建10條項目',
  maxHundredItems: '最多僅創建100條項目',
  noAbandonInEnabled: '啟用狀態下網域不可進行廢棄',
  domainReplaceMessage: '輸入網域與廢棄歷史清單內重疊，請輸入其他網域',
  isAbandonDomain: '{0}為廢棄網域',
  resetSuccess: '重置成功',
  abandonDomain: '廢棄網域',
  abandonTime: '廢棄時間',
  rechargeToGetSvip: '充值送SVIP',
  singleRechargeFull: '單筆充值滿',
  sendMember: '送會員',
  isNoLessThan: '大於等於',
  isLessThan: '小於',
  rechargeToGetSvipTips:
    '數字須為0-999999，不可重疊、充值欄位數目區間前者不可大於後者，不可輸入負數、空位、符號。',
  membershipDetails: '會員身份詳情',
  querySource: '查詢來源',
  source: '來源',
  obtainTime: '取得時間',
  domainName: '域名',
  firstRechargeBonus: '首次充值優惠',
  firstBonusRatio: '首充紅利比例',
  firstBonusRatioPlaceholder: '請輸入首充紅利比例',
  firstBonusContent: '首充文案內容',
  firstBonusContentPlaceholder: '請輸入首充文案內容',
  taskRewardConfig: '任務獎勵配置',
  openMission: '任務開啟',
  closeMission: '任務關閉',
  SVIP: '超級會員',
  GAME_COIN: '遊戲彩金',
  VIDEO_CARD: '觀影券',
  giftDays: '贈送天數',
  giftBonus: '贈送彩金',
  giftCoupons: '贈送券數',
  saveConfig: '保存配置',
  webAndMailConfig: '任務網域＆郵件配置',
  addMission: '新增任務',
  mission: '任務',
  missionTitle: '任務標題',
  missionSubtitle: '任務副標',
  domain: '網域',
  buttonText: '按鈕文字',
  sendMailMessage: '寄送郵件訊息',
  clickLinkMission: '點擊連結任務',
  sendMailMission: '郵箱寄送任務',
  saveOfficialLinkMission: '官方連結保存任務',
  nWordLimit: '僅限{0}個字元',
  pleaseEnterValidUrl: '請輸入有效網址',
  fourWorldLimit: '僅限4個字元',
  maximumOfFiveMissions: '任務最多建立5個',
  atLeastOpenOneAward: '請至少開啟一個獎勵',
  pleaseSelectMissionType: '請選擇任務類型',
  questionnairePlace: '問卷位置',
  questionnaireTitle: '問卷標題',
  questionnaireType: '問卷類型',
  questionnaireContent: '問卷內容',
  statistics: '統計',
  closingTime: '關閉時間',
  questionnaireEdit: '問卷調查編輯',
  questionnaireStatistics: '問卷統計',
  whetherToDelete: '是否確定刪除?',
  otherReasons: '其他原因',
  onlyOneSelect: '單選',
  multipleSelect: '複選',
  questionnairePositionType1: '充值-取消訂單',
  questionnairePositionType2: '提現-取消訂單',
  details: '詳請',
  startingTime: '起迄時間',
  participantNum: '參與統計次數',
  questionNature: '問題性質',
  select: '選擇',
  numberOfChoices: '選擇次數',
  questionnaireTitlePlaceholder: '請輸入問卷名稱，限10個字',
  questionNatureToolTips:
    '自行輸入：只能選1個,選第2個的提示語：自行輸入只能選1個,自行輸入不是最後1項時，保存後自動調整至最後1個，其它依序往上移。',
  cancelTypePlaceholder: '限15個字',
  questionnaireErrorMsg1: '啟用中的問卷無法刪除',
  questionnaireErrorMsg2: '請輸入問題內容',
  time: '時間',
  percentage: '佔比(%)',
  firstRechargeFeedbackTips:
    '數字須為1-999999，不可重疊、充值欄位數目區間前者不可大於後者，不可輸入負數、空位、符號。',
  confirmSave: '確認設置',
  firstChargerSettings: '首充優惠設定',
  RechargeIncreaseMoneyFeedbackSettings: '充值增金回饋設定',
  giftMoney: '贈金',
  advantageIncreasedGoldDiscount: '充值增金優惠',
  registrationSource: '註冊來源',
  promotionCode: '推廣碼',
  equipmentInfo: '設備資訊',
  ip: 'ip地址',
  deviceNumber: '設備號碼',
  assetInfo: '資產資訊',
  ticketsRemainder: '观影券余數',
  integralRemainder: '積分餘數',
  accountInfo: '帳務資訊',
  accumulatedVipPurchases: '累計購買VIP',
  accumulatedRecharge: '累計充值{0}',
  accumulatedGameCoinExchange: '累計遊戲幣兌換',
  accumulatedWithdraw: '累計{0}提現',
  accumulatedGameCoinConsumption: '累計遊戲彩金消耗',
  accumulatedUseOfTickets: '累計觀影券使用',
  deviceModel: '設備型號',
  deviceSystem: '設備系統',
  agentInfo: '代理資訊',
  nationalAgentLevel: '全民代理等級',
  directSuperiorID: '遊戲上級代理ID',
  agentBindingTime: '遊戲代理綁定時間',
  gameAgentIdentity: '遊戲代理身份',
  videoRecommendationTendencySetting: '視頻推薦傾向設定',
  popularDescription: '*數值越小，推薦大眾瀏覽視頻越多。',
  specializationDescription: '*數值越高，推薦更專注於用戶互動下的標籤視頻越多。',
  popular: '熱門大眾化',
  specialization: '各人專屬化',
  videoRecommendationWeightSetting: '視頻推薦權重設定',
  weightSettingDescription: '*請輸入介於1.0 ~ 2.0之間的數字，不能為空值。',
  userBehavior: '用戶行為',
  FAVORITE: '用戶喜好',
  INTERACT: '用戶互動',
  weightContent: '權重內容',
  VIDEO_WATCH_TIME: '視頻觀看時間',
  CLICK_VIDEO_TAG: '點擊視頻標籤',
  ADD_TAG: '增加自定義標籤',
  VIDEO_LIKE: '視頻點贊',
  VIDEO_COLLECT: '視頻收藏',
  VIDEO_DISCUSS: '視頻評論',
  DANMU_MESSAGE: '彈幕留言',
  weightedProportion: '加權比重',
  weightPlaceholderText: '1.0 ~ 2.0',
  confirmSetting: '確認設置',
  passwordRule: '需包含大、小字母、數字、特殊符號，長度8-16位',
  promotionLinkTemplate: '推廣連結模板',
  landingPageTemplate: '落地頁模板',
  openOrNot: '是否開放',
  promotionLinkLandingPage: '推廣連結落地頁',
  promotionalLink: '推廣連結',
  getLink: '獲取連結',
  getQRCode: '獲取二維碼',
  noData: '暫無數據',
  CLICK_VIDEO: '點擊視頻',
  parameter: '參數',
  gameAgentSetting: '遊戲代理設置',
  setAgentIdentity: '設定代理身份',
  setAgentContract: '設定代理契約',
  settingMethodTips: '設置方式：由最小值到最大值',
  serialNumberOfRule: '規則序號',
  dividedRatio: '分成比例(%)',
  netWinLossOfMonth: '當月淨輸贏',
  netWinLossMaximumTips: '無限大=999999999',
  minimumRequirementsForActiveUsers: '活躍用戶最低要求',
  activeUsersDefinition: '活躍用戶定義：當月充值>=100 或有效投注>=500',
  maximum10: '最多10筆',
  noSmallerThan: '不可比規則{index}小',
  overlappingWith: '與規則{index}重迭',
  noEmpty: '不可為空',
  agentTreeMigration: '代理樹遷移',
  agentIdentity: '代理身份',
  superiorAgentIDBeforeModify: '修改前上級代理ID',
  superiorAgentIDAfterModify: '修改後上級代理ID',
  pleaseEnterSuperiorID: '請輸入上級ID',
  agentTreeMigrationTips: '提醒：上級更換後，所有下級契約需重新簽約。',
  gameAgentContractDetail: '遊戲代理契約明細',
  contractContent: '契約內容',
  sendContractTime: '發送契約時間',
  contractConfirmTime: '契約確認時間',
  numberOfPeople: '人數',
  numberOfRule: '規則{index}',
  winOrLose: '輸贏',
  numberOfActivePeople: '活躍人數',
  divided: '分成',
  applicantID: '申請人ID',
  APPLICATION_TIME: '申請時間',
  REVIEW_TIME: '審核時間',
  reviewResult: '審核結果',
  hasSuperior: '是否有上級',
  applicantNickname: '申請人暱稱',
  applicationPassed: '申請通過',
  beRejectedTips: '該用戶曾申請過被拒絕，請確定是否要通過',
  addGameCommission: '加遊戲佣金',
  deductGameCommission: '扣遊戲佣金',
  getGameAgentPromotionLink: '獲取遊戲代理推廣鏈結',
  gameCommissionBalance: '遊戲佣金餘額',
  gameCommission: '遊戲佣金',
  gameCommissionAccount: '遊戲佣金賬戶',
  nowGameommissionBalance: '當前遊戲佣金餘額',
  auditRecords: '審核記錄',
  chooseMonth: '請選擇月份',
  searchAgentID: '查詢代理ID',
  agentID: '代理ID',
  agentNickname: '代理暱稱',
  Month: '月份',
  agentNetProfit: '代理沖正淨輸贏',
  rebateRatio: '返傭比例(%)',
  teamCommission: '團隊佣金',
  personalCommission: '個人佣金',
  lowerLevelTotalCommission: '下級總佣金',
  modifyPersonalCommission: '調整個人佣金',
  beforeModify: '調整前',
  afterModify: '調整後',
  remarkLimitHundredWords: '備註限100個字',
  personalCommissionDetail: '個人佣金詳情',
  modifyTime: '調整時間',
  operatedAccount: '操作賬號',
  onlyShowTheLastTenRecords: '僅顯示近10次記錄',
  COMMISSION_TRANSFER_OTHERS: '佣金轉{0}',
  PLATFORM_TRANSFER_IN: '平台轉入',
  SUPERIOR_TRANSFER_IN: '上級轉入',
  gameCoin: '遊戲幣',
  PAY_TO_SUB_AGENT: '支付下級',
  routePathValidateMessage: '路徑開頭應為 cy.',
};
